import { DriveType, MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS, TechnicalSpecification } from "@dtm-frontend/shared/uav";
import {
    CENTIMETERS_IN_METER,
    DateUtils,
    ISO8601TimeDuration,
    METERS_IN_KILOMETER,
    SECONDS_IN_HOUR,
    SECONDS_IN_MINUTE,
} from "@dtm-frontend/shared/utils";

export interface EditableCustomUavFlightTechnicalProperties {
    numberOfEngines: number | null;
    driveType: DriveType;
    minRecommendedAmbientTemperature: number | null;
    maxRecommendedAmbientTemperature: number | null;
    takeOffMass: number;
    maxTakeOffMass: number;
    maxDroneWidth: number;
    maxFlightTime: number;
    hasFlightSpeedLimit: boolean;
    maxFlightSpeed: number;
    minFlightSpeed?: number | null;
    maxClimbSpeed: number | null;
    maxDescentSpeed: number | null;
    maxWind: number | null;
    maxFlightAltitude: number | null;
    hasRainFlightPossibility: boolean;
}

type FlightTechnicalSpecification = Omit<
    TechnicalSpecification,
    "failSafe" | "hasGeofencing" | "hasDetectAndAvoid" | "hasProximitySensors" | "hasModuleRedundancy" | "hasGeocage"
>;

export class CustomUavFlightTechnicalPropertiesUtils {
    public static getFormValuesBySetupDefaults(
        technicalSpecification: FlightTechnicalSpecification | null
    ): EditableCustomUavFlightTechnicalProperties | null {
        if (!technicalSpecification) {
            return null;
        }

        const minFlightSpeed = technicalSpecification.minFlightSpeed
            ? +CustomUavFlightTechnicalPropertiesUtils.convertMetersPerSecondToKilometersPerHour(
                  technicalSpecification.minFlightSpeed
              ).toFixed(MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS)
            : null;
        const maxFlightTime = technicalSpecification.maxFlightTime
            ? CustomUavFlightTechnicalPropertiesUtils.convertDurationToMinutes(technicalSpecification.maxFlightTime)
            : null;

        if (!maxFlightTime) {
            return null;
        }

        return {
            numberOfEngines: technicalSpecification.numberOfEngines,
            driveType: technicalSpecification.driveType,
            minRecommendedAmbientTemperature: technicalSpecification.minRecommendedAmbientTemperature,
            maxRecommendedAmbientTemperature: technicalSpecification.maxRecommendedAmbientTemperature,
            takeOffMass: technicalSpecification.takeOffMass,
            maxTakeOffMass: technicalSpecification.maxTakeOffMass,
            maxDroneWidth: CustomUavFlightTechnicalPropertiesUtils.convertMetersToCentimeters(technicalSpecification.maxDroneWidth),
            maxFlightTime,
            hasFlightSpeedLimit: technicalSpecification.hasFlightSpeedLimit,
            maxFlightSpeed: +CustomUavFlightTechnicalPropertiesUtils.convertMetersPerSecondToKilometersPerHour(
                technicalSpecification.maxFlightSpeed
            ).toFixed(MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS),
            minFlightSpeed: minFlightSpeed ?? undefined,
            maxClimbSpeed: technicalSpecification.maxClimbSpeed,
            maxDescentSpeed: technicalSpecification.maxDescentSpeed,
            maxWind: technicalSpecification.maxWind,
            maxFlightAltitude: technicalSpecification.maxFlightAltitude,
            hasRainFlightPossibility: technicalSpecification.hasRainFlightPossibility,
        };
    }

    public static prepareResultTechnicalSpecificationSetup(
        properties: EditableCustomUavFlightTechnicalProperties,
        isMinFlightSpeedVisible: boolean
    ): FlightTechnicalSpecification {
        let minFlightSpeed;
        if (isMinFlightSpeedVisible) {
            minFlightSpeed = properties.minFlightSpeed
                ? CustomUavFlightTechnicalPropertiesUtils.convertKilometersPerHourToMetersPerSecond(
                      +properties.minFlightSpeed.toFixed(MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS)
                  )
                : null;
        }

        return {
            numberOfEngines: properties.numberOfEngines,
            driveType: properties.driveType,
            minRecommendedAmbientTemperature: properties.minRecommendedAmbientTemperature,
            maxRecommendedAmbientTemperature: properties.maxRecommendedAmbientTemperature,
            takeOffMass: properties.takeOffMass,
            maxTakeOffMass: properties.maxTakeOffMass,
            maxDroneWidth: CustomUavFlightTechnicalPropertiesUtils.convertCentimetersToMeters(properties.maxDroneWidth),
            maxFlightTime: DateUtils.convertSecondsToISO8601Duration(properties.maxFlightTime * SECONDS_IN_MINUTE),
            hasFlightSpeedLimit: properties.hasFlightSpeedLimit,
            maxFlightSpeed: CustomUavFlightTechnicalPropertiesUtils.convertKilometersPerHourToMetersPerSecond(
                +properties.maxFlightSpeed.toFixed(MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS)
            ),
            minFlightSpeed,
            maxClimbSpeed: properties.maxClimbSpeed,
            maxDescentSpeed: properties.maxDescentSpeed,
            maxWind: properties.maxWind,
            maxFlightAltitude: properties.maxFlightAltitude,
            hasRainFlightPossibility: properties.hasRainFlightPossibility,
        };
    }

    private static convertMetersPerSecondToKilometersPerHour(value: number) {
        return (value * SECONDS_IN_HOUR) / METERS_IN_KILOMETER;
    }

    private static convertKilometersPerHourToMetersPerSecond(value: number) {
        return (value * METERS_IN_KILOMETER) / SECONDS_IN_HOUR;
    }

    private static convertMetersToCentimeters(value: number) {
        return value * CENTIMETERS_IN_METER;
    }

    private static convertCentimetersToMeters(value: number) {
        return value / CENTIMETERS_IN_METER;
    }

    private static convertDurationToMinutes(maxFlightTime: ISO8601TimeDuration): number | null {
        const seconds = DateUtils.convertISO8601DurationToSeconds(maxFlightTime);

        if (seconds !== null) {
            return Math.round(seconds / SECONDS_IN_MINUTE);
        }

        return null;
    }
}
