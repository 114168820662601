import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { CommunicationFrequency } from "@dtm-frontend/shared/uav";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface FrequencyForm {
    asRange: FormControl<boolean>;
    minGhz: FormControl<number | null>;
    maxGhz: FormControl<number | null>;
}

interface FrequencyFormValues {
    asRange: boolean;
    minGhz: number;
    maxGhz: number;
}

const MAX_FREQUENCY_VALUE = 1000;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-frequencies-control",
    templateUrl: "./frequencies-control.component.html",
    styleUrls: ["./frequencies-control.component.scss", "../../setup-form-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FrequenciesControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FrequenciesControlComponent),
            multi: true,
        },
    ],
})
export class FrequenciesControlComponent implements ControlValueAccessor, Validator {
    protected readonly MIN_FREQUENCY_VALUE = 0;
    protected readonly MAX_FREQUENCY_VALUE = MAX_FREQUENCY_VALUE;

    protected readonly frequenciesArray = new FormArray<FormGroup<FrequencyForm>>([]);
    protected readonly frequenciesForm = new FormGroup<{ frequencies: FormArray }>({
        frequencies: this.frequenciesArray,
    });

    private onTouched = FunctionUtils.noop;
    private propagateChange: (value: CommunicationFrequency[] | null) => void = FunctionUtils.noop;

    constructor() {
        this.frequenciesArray.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.propagateChange(values.map((value) => this.prepareFrequencyValue(value as FrequencyFormValues)));
        });
    }

    public registerOnChange(fn: (value: CommunicationFrequency[] | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: CommunicationFrequency[] | undefined): void {
        this.initFrequenciesForm(value ?? []);
    }

    public validate(): ValidationErrors | null {
        if (this.frequenciesArray.invalid) {
            return { frequencies: true };
        }

        return null;
    }

    protected addFrequency(): void {
        this.frequenciesArray.push(this.createFrequencyForm());
    }

    protected removeFrequency(index: number): void {
        this.frequenciesArray.removeAt(index);
    }

    private initFrequenciesForm(communications: CommunicationFrequency[]): void {
        this.frequenciesArray.clear({ emitEvent: false });

        if (!communications.length) {
            this.frequenciesArray.push(this.createFrequencyForm(), { emitEvent: false });

            return;
        }

        for (const communication of communications) {
            this.frequenciesArray.push(this.createFrequencyForm(communication), { emitEvent: false });
        }
    }

    private createFrequencyForm(frequency?: CommunicationFrequency): FormGroup<FrequencyForm> {
        const asRangeValue = !!(frequency && frequency.minGhz !== frequency.maxGhz);

        const frequencyForm = new FormGroup<FrequencyForm>(
            {
                asRange: new FormControl<boolean>(asRangeValue, {
                    validators: [Validators.required],
                    nonNullable: true,
                }),
                minGhz: new FormControl<number | null>(frequency?.minGhz ?? null, {
                    validators: [Validators.required, Validators.min(this.MIN_FREQUENCY_VALUE), Validators.max(this.MAX_FREQUENCY_VALUE)],
                    nonNullable: true,
                }),
                maxGhz: new FormControl<number | null>(frequency?.maxGhz ?? null, {
                    nonNullable: true,
                }),
            },
            ({ value }) => this.getFrequencyRangeValidator(value)
        );

        this.setFrequencyValidators(frequencyForm, asRangeValue);

        frequencyForm.controls.asRange.valueChanges.pipe(untilDestroyed(this)).subscribe((asRange: boolean) => {
            this.setFrequencyValidators(frequencyForm, asRange);

            frequencyForm.controls.minGhz.updateValueAndValidity();
            frequencyForm.controls.maxGhz.updateValueAndValidity();
        });

        return frequencyForm;
    }

    private setFrequencyValidators(frequencyForm: FormGroup<FrequencyForm>, asRange: boolean) {
        if (asRange) {
            frequencyForm.controls.maxGhz.setValidators([
                Validators.required,
                Validators.min(this.MIN_FREQUENCY_VALUE),
                Validators.max(this.MAX_FREQUENCY_VALUE),
            ]);
        } else {
            frequencyForm.controls.maxGhz.clearValidators();
        }
    }

    private getFrequencyRangeValidator(value: FrequencyFormValues): ValidationErrors | null {
        if (value.asRange && value.minGhz !== null && value.maxGhz !== null && value.minGhz >= value.maxGhz) {
            return { range: true };
        }

        return null;
    }

    private prepareFrequencyValue(frequencyValue: FrequencyFormValues): CommunicationFrequency {
        return {
            minGhz: frequencyValue.minGhz,
            maxGhz: frequencyValue.asRange ? frequencyValue.maxGhz : frequencyValue.minGhz,
        };
    }
}
