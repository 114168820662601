import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NavigationAccuracy, Tracker } from "@dtm-frontend/shared/uav";
import { FILES_UPLOAD_API_PROVIDER } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { map } from "rxjs/operators";
import { SetupDocumentUploadApiService } from "../../../../services/setup-document-upload-api.service";
import { EditableCustomUavInitialSetup, EditableCustomUavSetup } from "../../../../services/uav.models";
import { CustomUavSetupFormComponent } from "../../../setup/setup-form/custom-uav-setup-form/custom-uav-setup-form.component";
import { NewCustomUavBasicInfo } from "../../new-uav-wizard.models";

interface NewUavWizardCustomUavPrimarySetupStepComponentState {
    basicInfo: NewCustomUavBasicInfo | undefined;
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
    primarySetup: EditableCustomUavSetup | undefined;
    isIntroMessageVisible: boolean;
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-new-uav-wizard-custom-uav-primary-setup-step[basicInfo][trackers][navigationAccuracyItems]",
    templateUrl: "./custom-uav-primary-setup-step.component.html",
    styleUrls: ["./custom-uav-primary-setup-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: SetupDocumentUploadApiService,
        },
    ],
    animations: [AnimationUtils.fadeAnimation()],
})
export class NewUavWizardCustomUavPrimarySetupStepComponent implements OnInit {
    @Input() public set basicInfo(value: NewCustomUavBasicInfo | undefined) {
        this.localStore.patchState({
            basicInfo: value,
            primarySetup: undefined,
        });
    }

    @Input() public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value ?? [] });
    }

    @Input() public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public back = new EventEmitter<void>();
    @Output() public next = new EventEmitter<EditableCustomUavSetup>();
    @Output() public valueChange = new EventEmitter<void>();

    @ViewChild(CustomUavSetupFormComponent, { static: true }) protected setupForm: CustomUavSetupFormComponent | undefined;

    protected readonly uavType$ = this.localStore.selectByKey("basicInfo").pipe(
        RxjsUtils.filterFalsy(),
        map((basicInfo) => basicInfo.type)
    );
    protected readonly setupDefaults$ = this.localStore.selectByKey("basicInfo").pipe(map(() => this.prepareSetupDefaults()));
    protected readonly trackers$ = this.localStore.selectByKey("trackers");
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly isIntroMessageVisible$ = this.localStore.selectByKey("isIntroMessageVisible");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    constructor(
        private readonly localStore: LocalComponentStore<NewUavWizardCustomUavPrimarySetupStepComponentState>,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            basicInfo: undefined,
            trackers: [],
            primarySetup: undefined,
            isIntroMessageVisible: true,
            isProcessing: false,
            navigationAccuracyItems: [],
        });
    }

    public ngOnInit(): void {
        this.setupForm?.initSetupFormValues();
    }

    protected goToNext() {
        const primarySetup = this.localStore.selectSnapshotByKey("primarySetup");

        if (primarySetup) {
            this.next.emit(primarySetup);
        } else {
            this.setupForm?.markInvalidField();
        }
    }

    protected closeIntroMessage() {
        this.localStore.patchState({ isIntroMessageVisible: false });
    }

    private prepareSetupDefaults(): EditableCustomUavInitialSetup {
        return {
            name: this.transloco.translate("dtmWebAppLibUav.newUavWizardSteps.primarySetup.primarySetupName"),
            technicalSpecification: null,
            communications: [],
            documents: {},
            equipment: null,
            trackings: [],
        };
    }

    protected primarySetupChange(setup: EditableCustomUavSetup | undefined) {
        this.localStore.patchState({
            primarySetup: setup,
        });
        this.valueChange.emit();
    }
}
