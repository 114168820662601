import { TemplatePortal } from "@angular/cdk/portal";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import {
    FormalJustification,
    MissionCategory,
    MissionDataSimple,
    MissionPlanAnalysisStatus,
    MissionPlanData,
    MissionPlanDataAndCapabilities,
    MissionPlanSpecificPermitType,
    MissionProcessingPhase,
    MissionType,
    MissionUAVSetup,
    MissionUtils,
    OperationalGeometryData,
    SoraSettings,
    TacticalMitigationPerformanceRequirementProperty,
    TrafficMissionData,
} from "@dtm-frontend/shared/mission";
import { ContextOperator, ItineraryEditorType, MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FormUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { audit, combineLatest } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";
import { MissionPlanVerificationType } from "../../../../models/mission-plan-verification.model";
import { MissionDataFormData, MissionPlanItineraryWithoutConstraints } from "../../../../models/mission.model";
import { AdditionalInformationSettings } from "../../../mission-notes-and-description/personal-notes.component";
import { MissionWizardSteps } from "../../content/mission-wizard-content.component";

interface MissionWizardSummaryStepComponentState {
    missionDataFormData: Partial<MissionDataFormData> | undefined;
    analysisStatus: MissionPlanAnalysisStatus | undefined;
    isProcessing: boolean;
    additionalInformation: AdditionalInformationSettings | undefined;
    stepNumber: number | undefined;
    stepsAmount: number | undefined;
    currentPlanDataAndCapabilities: MissionPlanDataAndCapabilities | undefined;
    route: MissionPlanRoute | undefined;
    nearbyMissions: TrafficMissionData[];
    collisionMissions: TrafficMissionData[];
    operators: { [key: string]: ContextOperator } | undefined;
    collisionZones: AirspaceElementsInfo | undefined;
    selectedZoneId: string | undefined;
    soraSettings: SoraSettings | undefined;
    setup: MissionUAVSetup | undefined;
    missionType: MissionType | undefined;
    operationalGeometryData: OperationalGeometryData | undefined;
    isDtmOnly: boolean;
}

const enum NEXT_BUTTON_LABEL_KEYS {
    ApplyForSpecificPermit = "dtmWebAppLibMission.summaryStep.applyForSpecificPermitButtonLabel",
    ManualVerificationRequired = "dtmWebAppLibMission.summaryStep.submitMissionWithManualValidatorButtonLabel",
    Submit = "dtmWebAppLibMission.summaryStep.submitMissionButtonLabel",
}

const FORM_UPDATES_DEBOUNCE_TIME = DEFAULT_DEBOUNCE_TIME;
const MAX_NAME_LENGTH = 50;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-summary-step[analysisStatus][missionDataFormData]",
    templateUrl: "./summary-step.component.html",
    styleUrls: ["./summary-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardSummaryStepComponent implements AfterViewInit {
    @ViewChild("routeSideViewTemplate", { read: TemplateRef }) public routeSideViewTemplate: TemplateRef<unknown> | undefined;

    @Input() public set missionDataFormData(value: Partial<MissionDataFormData> | undefined) {
        this.localStore.patchState({ missionDataFormData: value });
    }
    @Input() public set analysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ analysisStatus: value });
    }
    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }
    @Input() public set additionalInformation(value: AdditionalInformationSettings | undefined) {
        this.localStore.patchState({ additionalInformation: value });
    }
    @Input() public set stepNumber(value: number | undefined) {
        this.localStore.patchState({ stepNumber: value });
    }
    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value });
    }
    @Input() public set currentPlanDataAndCapabilities(value: MissionPlanDataAndCapabilities | undefined) {
        this.localStore.patchState({ currentPlanDataAndCapabilities: value, isDtmOnly: !!value?.plan.route?.isDtmOnly });
        const name = value?.plan?.information?.name;
        if (name) {
            this.missionNameFormControl.setValue(name, { emitEvent: false });
        }
    }
    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }
    @Input() public set nearbyMissions(value: TrafficMissionData[] | undefined) {
        this.localStore.patchState({ nearbyMissions: value ?? [] });
    }
    @Input() public set collisionMissions(value: TrafficMissionData[] | undefined) {
        this.localStore.patchState({ collisionMissions: value ?? [] });
    }
    @Input() public set operators(value: { [key: string]: ContextOperator } | undefined) {
        this.localStore.patchState({ operators: value });
    }
    @Input() public set collisionZones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ collisionZones: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Input() public set currentPlanItinerary(value: MissionPlanItineraryWithoutConstraints | undefined) {
        if (value?.type !== ItineraryEditorType.None) {
            this.localStore.patchState({ soraSettings: value?.soraSettings });
        }
    }
    @Input() public set setup(value: MissionUAVSetup | undefined) {
        this.localStore.patchState({ setup: value });
    }
    @Input() public set missionType(value: MissionType | undefined) {
        this.localStore.patchState({ missionType: value });
    }
    @Input() public set operationalGeometryData(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometryData: value });
    }

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly missionSubmit = new EventEmitter<MissionPlanVerificationType>();
    @Output() public readonly specificPermitApply = new EventEmitter<void>();
    @Output() public readonly edit = new EventEmitter<void>();
    @Output() public readonly additionalInformationChange = new EventEmitter<AdditionalInformationSettings>();
    @Output() public readonly formalJustificationChange = new EventEmitter<FormalJustification | undefined>();
    @Output() public readonly flyToRoute = new EventEmitter<string>();
    @Output() public readonly flyToMainMissionRoute = new EventEmitter<void>();
    @Output() public readonly otherMissionSelectedId = new EventEmitter<string>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    private templatePortal: TemplatePortal | undefined;
    @Output() public readonly sideViewTemplate = new EventEmitter<TemplatePortal | undefined>();

    protected readonly MissionType = MissionType;
    protected readonly MissionWizardSteps = MissionWizardSteps;
    protected readonly ItineraryEditorType = ItineraryEditorType;
    protected readonly TacticalMitigationPerformanceRequirement = TacticalMitigationPerformanceRequirementProperty;
    protected readonly MissionPlanSpecificPermitType = MissionPlanSpecificPermitType;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly MissionCategory = MissionCategory;

    protected readonly missionNameFormControl = new UntypedFormControl(null, [
        FormUtils.trimmedRequiredValidator,
        FormUtils.trimmedMaxLengthValidator(MAX_NAME_LENGTH),
    ]);

    protected readonly analysisStatus$ = this.localStore.selectByKey("analysisStatus");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly additionalInformation$ = this.localStore.selectByKey("additionalInformation");
    protected readonly stepNumber$ = this.localStore.selectByKey("stepNumber");
    protected readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount");
    protected readonly currentPlanDataAndCapabilities$ = this.localStore.selectByKey("currentPlanDataAndCapabilities");
    protected readonly missionPlan$ = this.currentPlanDataAndCapabilities$.pipe(map((data) => data?.plan));
    protected readonly route$ = this.localStore.selectByKey("route");
    protected readonly nearbyMissions$ = this.localStore.selectByKey("nearbyMissions");
    protected readonly collisionMissions$ = this.localStore.selectByKey("collisionMissions");
    protected readonly zones$ = this.localStore.selectByKey("collisionZones").pipe(map(MissionUtils.partitionCollisionZones));
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly setup$ = this.localStore.selectByKey("setup");
    protected readonly missionType$ = this.localStore.selectByKey("missionType");
    protected readonly operationalGeometryData$ = this.localStore.selectByKey("operationalGeometryData");
    protected readonly isDtmOnly$ = this.localStore.selectByKey("isDtmOnly");
    protected readonly missionDataSimple$ = combineLatest([
        this.currentPlanDataAndCapabilities$.pipe(RxjsUtils.filterFalsy()),
        this.route$.pipe(RxjsUtils.filterFalsy()),
        this.localStore.selectByKey("operators"),
    ]).pipe(
        map(([currentPlanDataAndCapabilities, route, operators]) =>
            this.prepareMissionDataSimple(currentPlanDataAndCapabilities, route, operators)
        )
    );

    constructor(
        private readonly localStore: LocalComponentStore<MissionWizardSummaryStepComponentState>,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        this.localStore.setState({
            missionDataFormData: undefined,
            analysisStatus: undefined,
            isProcessing: false,
            additionalInformation: undefined,
            stepNumber: undefined,
            stepsAmount: undefined,
            route: undefined,
            nearbyMissions: [],
            collisionMissions: [],
            operators: undefined,
            currentPlanDataAndCapabilities: undefined,
            collisionZones: undefined,
            selectedZoneId: undefined,
            soraSettings: undefined,
            setup: undefined,
            missionType: undefined,
            operationalGeometryData: undefined,
            isDtmOnly: false,
        });
        this.watchMissionNameUpdates();
    }

    public ngAfterViewInit(): void {
        if (this.routeSideViewTemplate) {
            this.templatePortal = new TemplatePortal(this.routeSideViewTemplate, this.viewContainerRef);
            this.sideViewTemplate.emit(this.templatePortal);
        }
    }

    private watchMissionNameUpdates() {
        this.missionNameFormControl.valueChanges
            .pipe(
                audit(() => this.isProcessing$.pipe(filter((isProcessing) => !isProcessing))),
                debounceTime(FORM_UPDATES_DEBOUNCE_TIME),
                untilDestroyed(this)
            )
            .subscribe((name) => {
                if (this.missionNameFormControl.invalid) {
                    return;
                }

                const information = this.localStore.selectSnapshotByKey("additionalInformation");
                this.additionalInformationChange.emit({ ...information, name, isNameDirty: true });
            });
    }

    protected next() {
        const analysisStatus = this.localStore.selectSnapshotByKey("analysisStatus");
        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");

        if (!analysisStatus || isProcessing) {
            return;
        }

        const { applyForSpecificPermit, isManualVerificationRequired, isPlanSubmittable } = analysisStatus;

        if (applyForSpecificPermit) {
            this.specificPermitApply.emit();

            return;
        }

        if (!isPlanSubmittable) {
            return;
        }

        this.missionSubmit.emit(isManualVerificationRequired ? MissionPlanVerificationType.Manual : MissionPlanVerificationType.Automatic);
    }

    protected nextButtonLabelKey(status?: MissionPlanAnalysisStatus): string {
        switch (true) {
            case status?.applyForSpecificPermit:
                return NEXT_BUTTON_LABEL_KEYS.ApplyForSpecificPermit;
            case status?.isManualVerificationRequired:
                return NEXT_BUTTON_LABEL_KEYS.ManualVerificationRequired;
            default:
                return NEXT_BUTTON_LABEL_KEYS.Submit;
        }
    }

    protected isAuthorityAcceptationConfirmed = MissionUtils.isAuthorityAcceptationConfirmed;

    protected isWholeDayMode({ category, phase }: MissionPlanData): boolean {
        return (
            (category?.type === MissionCategory.Specific &&
                category.specificPermitType === MissionPlanSpecificPermitType.Individual &&
                !category.specificCaaPermitId) ||
            phase === MissionProcessingPhase.CaaPermitApplication
        );
    }

    private prepareMissionDataSimple(
        { plan, flightPurposes }: MissionPlanDataAndCapabilities,
        route: MissionPlanRoute,
        operators: { [key: string]: ContextOperator } | undefined
    ): MissionDataSimple {
        return {
            isRoutePathBased: !!route?.isPathBased,
            flightStartAtMin: plan.flightStartAtMin,
            flightStartAtMax: plan.flightStartAtMax,
            flightFinishAtMin: plan.flightFinishAtMin,
            flightFinishAtMax: plan.flightFinishAtMax,
            phase: plan.phase,
            distance: route?.estimatedDistance,
            operatorName: operators?.[plan.capabilities.operator]?.name,
            pilotName: plan.capabilities.pilotName,
            uavName:
                plan.capabilities.uavName && plan.capabilities.setupName
                    ? `${plan.capabilities.uavName} (${plan.capabilities.setupName})`
                    : undefined,
            uavSerialNumbers: plan.uav.serialNumbers ?? [],
            trackersIdentifiers: plan.capabilities.trackersIdentifiers ?? [],
            category: plan.category,
            flightPurpose: {
                nameTranslationKey: flightPurposes?.find((purpose) => purpose.id === plan.flightPurpose?.id)?.name ?? "",
                comment: plan.flightPurpose?.comment ?? undefined,
            },
            additionalCrew: plan.capabilities.additionalCrew,
        };
    }
}
