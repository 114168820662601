import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { WizardActions } from "@dtm-frontend/shared/ui/wizard";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, switchMap } from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { OperatorType } from "../../../pilot-operator-registration/shared/models";
import { OperatorContextActions } from "../../../shared/operator-context/state/operator-context.actions";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import {
    AuthenticationDetails,
    LegalOperatorFormValue,
    OPERATOR_MIGRATION_WIZARD_ID,
    OperatorMigrationDetails,
    OperatorMigrationErrorType,
    OperatorMigrationWizardSteps,
} from "../../models/operator-migration.models";
import { OperatorMigrationActions } from "../../state/operator-migration.actions";
import { OperatorMigrationState } from "../../state/operator-migration.state";

interface OperatorMigrationWizardContentComponentState {
    migrationDetails: OperatorMigrationDetails | undefined;
    authenticationDetails: AuthenticationDetails | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-migration-wizard-content",
    templateUrl: "./operator-migration-wizard-content.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorMigrationWizardContentComponent {
    protected readonly OPERATOR_MIGRATION_WIZARD_ID = OPERATOR_MIGRATION_WIZARD_ID;
    protected readonly OperatorMigrationWizardSteps = OperatorMigrationWizardSteps;
    protected readonly isProcessing$ = this.store.select(OperatorMigrationState.isProcessing).pipe(
        combineLatestWith(this.store.select(OperatorContextState.isProcessing)),
        map(
            ([isOperatorMigrationProcessing, isGlobalCapabilitiesProcessing]) =>
                isOperatorMigrationProcessing || isGlobalCapabilitiesProcessing
        )
    );
    protected readonly authenticationError$ = this.store.select(OperatorMigrationState.authenticationError);
    protected readonly importedDetails$ = this.store.select(OperatorMigrationState.importedDetails);
    protected readonly migrationDetails$ = this.localStore.selectByKey("migrationDetails");
    protected readonly authenticationDetails$ = this.localStore.selectByKey("authenticationDetails");

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<OperatorMigrationWizardContentComponentState>,
        private readonly toastr: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router
    ) {
        localStore.setState({ migrationDetails: undefined, authenticationDetails: undefined });
        this.goToAuthenticationStep();
    }

    protected authenticate(authenticationDetails: AuthenticationDetails) {
        this.localStore.patchState({ authenticationDetails });

        this.store
            .dispatch(new OperatorMigrationActions.ImportDetails(authenticationDetails))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const authenticationError = this.store.selectSnapshot(OperatorMigrationState.authenticationError);
                if (authenticationError) {
                    if (authenticationError.type === OperatorMigrationErrorType.DetailsAlreadyImportedByOtherUser) {
                        this.toastr.error(
                            this.transloco.translate("dtmWebAppLibOperatorMigration.container.detailsAlreadyImportedByOtherUser")
                        );
                    }

                    return;
                }
                this.goToDataImportStep();
                this.localStore.patchState({ migrationDetails: undefined });
            });
    }

    protected goToAuthenticationStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(OPERATOR_MIGRATION_WIZARD_ID, OperatorMigrationWizardSteps.Authentication),
            new WizardActions.EnableSteps(OPERATOR_MIGRATION_WIZARD_ID, [OperatorMigrationWizardSteps.Authentication]),
        ]);
    }

    protected goToResetPasswordStep() {
        this.store.dispatch([new WizardActions.SetActiveStep(OPERATOR_MIGRATION_WIZARD_ID, OperatorMigrationWizardSteps.ResetPassword)]);
    }

    protected saveDetailsStep(details: OperatorMigrationDetails) {
        this.localStore.patchState({ migrationDetails: details });
        this.goToSummary();
    }

    protected goToDataImportStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(OPERATOR_MIGRATION_WIZARD_ID, OperatorMigrationWizardSteps.DataImport),
            new WizardActions.EnableSteps(OPERATOR_MIGRATION_WIZARD_ID, [OperatorMigrationWizardSteps.DataImport]),
        ]);
    }

    protected saveMigrationDetails() {
        const migrationDetails = this.localStore.selectSnapshotByKey("migrationDetails");
        const operatorType = migrationDetails?.legalOperator ? OperatorType.Enterprise : OperatorType.Personal;

        if (!migrationDetails) {
            return;
        }

        if (operatorType === OperatorType.Enterprise && migrationDetails.legalOperator) {
            this.migrateEnterpriseOperator(migrationDetails.legalOperator);

            return;
        }

        this.migratePersonalOperator(migrationDetails);
    }

    protected goToSummary() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(OPERATOR_MIGRATION_WIZARD_ID, OperatorMigrationWizardSteps.Summary),
            new WizardActions.EnableSteps(OPERATOR_MIGRATION_WIZARD_ID, [OperatorMigrationWizardSteps.Summary]),
        ]);
    }

    protected submitResetPasswordForm(email: string) {
        this.localStore.patchState({ authenticationDetails: undefined });

        this.store
            .dispatch(new OperatorMigrationActions.ResetPassword(email))
            .pipe(
                switchMap(() => this.store.select(OperatorMigrationState.resetPasswordError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.goToAuthenticationStep();
                    this.toastr.success(
                        this.transloco.translate("dtmWebAppLibOperatorMigration.resetPasswordStep.resetPasswordRequestSuccess")
                    );

                    return;
                }

                this.toastr.error(this.transloco.translate("dtmWebAppLibOperatorMigration.resetPasswordStep.resetPasswordRequestError"));
            });
    }

    protected cancelPasswordReset() {
        this.goToAuthenticationStep();
    }

    private migrateEnterpriseOperator(migrationDetails: LegalOperatorFormValue) {
        this.store
            .dispatch(new OperatorMigrationActions.MigrateEnterpriseOperator(migrationDetails))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.handleMigrationResponse());
    }

    private migratePersonalOperator(migrationDetails: OperatorMigrationDetails) {
        this.store
            .dispatch(new OperatorMigrationActions.MigratePersonalOperator(migrationDetails))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.handleMigrationResponse());
    }

    private async handleMigrationResponse() {
        const migratedOperatorId = this.store.selectSnapshot(OperatorMigrationState.migratedOperatorId);
        const migrationError = this.store.selectSnapshot(OperatorMigrationState.migrationError);

        if (migrationError) {
            switch (migrationError.type) {
                case OperatorMigrationErrorType.PersonalOperatorAlreadyExists:
                    this.toastr.error(
                        this.transloco.translate("dtmWebAppLibOperatorMigration.container.personalOperatorAlreadyExistsError")
                    );

                    return;
                case OperatorMigrationErrorType.CompanyNumberAlreadyUsed:
                    this.toastr.error(this.transloco.translate("dtmWebAppLibOperatorMigration.container.companyNumberAlreadyUsedError"));

                    return;
                default:
                    this.toastr.error(this.transloco.translate("dtmWebAppLibOperatorMigration.container.migrationError"));
            }

            return;
        }
        if (!migratedOperatorId) {
            this.toastr.error(this.transloco.translate("dtmWebAppLibOperatorMigration.container.migrationError"));

            return;
        }

        await lastValueFrom(this.store.dispatch(OperatorContextActions.GetGlobalCapabilities));

        const userContext = this.store.selectSnapshot(OperatorContextState.operator(migratedOperatorId));
        if (userContext) {
            this.store.dispatch(new OperatorContextActions.TrySelectContext(userContext));
        }

        // NOTE: we need to wait one cycle to make sure the context is selected
        setTimeout(() => this.router.navigate(["/operator-profile"]));
    }
}
