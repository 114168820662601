import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, ViewChild, forwardRef } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { CommunicationFrequency, CommunicationLinkType, CommunicationType } from "@dtm-frontend/shared/uav";
import { ButtonTheme, ConfirmationDialogComponent, InvalidFormScrollableDirective, ScrollHookDirective } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, MILLISECONDS_IN_SECOND, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EditableCommunication } from "./communications-utils";

interface CommunicationsControlComponentState {
    factoryCommunicationTypes: CommunicationType[];
    isCustomUavMode: boolean;
}

interface CommunicationForm {
    id: FormControl<string | null>;
    type: FormControl<CommunicationType | null>;
    linkDelay: FormControl<number | null>;
    linkType: FormControl<CommunicationLinkType>;
    transmissionLinkRange: FormControl<number | null>;
    isEmbedded: FormControl<boolean>;
    frequencies: FormControl<CommunicationFrequency[]>;
}

// eslint-disable-next-line no-magic-numbers
const MAX_LINK_DELAY_VALUE = 10 * MILLISECONDS_IN_SECOND;
const MAX_TRANSMISSION_LINK_RANGE_VALUE = 40000;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-communications-control",
    templateUrl: "./communications-control.component.html",
    styleUrls: ["./communications-control.component.scss", "../setup-form-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CommunicationsControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CommunicationsControlComponent),
            multi: true,
        },
    ],
})
export class CommunicationsControlComponent implements ControlValueAccessor, Validator {
    protected readonly MIN_LINK_DELAY_VALUE = 1;
    protected readonly MAX_LINK_DELAY_VALUE = MAX_LINK_DELAY_VALUE;
    protected readonly MIN_TRANSMISSION_LINK_RANGE_VALUE = 0;
    protected readonly MAX_TRANSMISSION_LINK_RANGE_VALUE = MAX_TRANSMISSION_LINK_RANGE_VALUE;
    protected readonly CommunicationLinkType = CommunicationLinkType;

    @Input() public set factoryCommunicationTypes(value: CommunicationType[] | undefined) {
        this.localStore.patchState({ factoryCommunicationTypes: value ?? [] });
    }

    @Input() public set isCustomUavMode(value: BooleanInput) {
        this.localStore.patchState({ isCustomUavMode: coerceBooleanProperty(value) });
    }

    @ViewChild(ScrollHookDirective) private addCommunicationHook!: ScrollHookDirective;
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly types$ = this.initAllowedTypes();
    protected readonly isCustomUavMode$ = this.localStore.selectByKey("isCustomUavMode");
    protected readonly communicationsControls$ = new BehaviorSubject<FormGroup<CommunicationForm>[]>([]);
    protected readonly communicationsArray = new FormArray<FormGroup<CommunicationForm>>([]);
    protected readonly communicationsForm = new FormGroup<{ communications: FormArray }>({
        communications: this.communicationsArray,
    });

    private readonly availableTypes = Object.values(CommunicationType);
    private onTouched = FunctionUtils.noop;
    private propagateChange: (value: EditableCommunication[] | null) => void = FunctionUtils.noop;

    constructor(
        private readonly localStore: LocalComponentStore<CommunicationsControlComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            factoryCommunicationTypes: [],
            isCustomUavMode: false,
        });

        this.communicationsArray.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            for (const communicationForm of this.communicationsArray.controls) {
                communicationForm.controls.type.updateValueAndValidity({ emitEvent: false });
            }

            this.propagateChange(values as EditableCommunication[]);
        });
    }

    public registerOnChange(fn: (value: EditableCommunication[] | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: EditableCommunication[] | undefined): void {
        this.initCommunicationsForm(value ?? []);
    }

    public validate(): ValidationErrors | null {
        if (this.communicationsArray.invalid) {
            return { communications: true };
        }

        return null;
    }

    public addCommunication(): void {
        this.communicationsArray.push(this.createCommunicationForm());
        this.updateCommunicationsControls();
        this.addCommunicationHook.scrollTo();
    }

    public scrollToFirstInvalidField() {
        this.invalidFormScrollable.scrollToFirstInvalidField({
            behavior: "smooth",
            block: "center",
        });
    }

    protected tryToRemoveCommunication(index: number): void {
        this.confirmCommunicationRemoval(this.communicationsArray.at(index).controls.type.value)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.removeCommunication(index);
            });
    }

    protected areFrequenciesRequired(type: CommunicationType | null): boolean {
        return type === CommunicationType.RADIO;
    }

    private initCommunicationsForm(communications: EditableCommunication[]): void {
        this.communicationsArray.clear({ emitEvent: false });

        for (const communication of communications) {
            this.communicationsArray.push(this.createCommunicationForm(communication), { emitEvent: false });
        }

        this.updateCommunicationsControls();
    }

    private initAllowedTypes() {
        return this.localStore
            .selectByKey("factoryCommunicationTypes")
            .pipe(map((factoryCommunicationTypes) => this.availableTypes.filter((type) => !factoryCommunicationTypes.includes(type))));
    }

    private createCommunicationForm(communication?: EditableCommunication): FormGroup<CommunicationForm> {
        const communicationForm = new FormGroup<CommunicationForm>({
            id: new FormControl<string | null>(communication?.id ?? null),
            type: new FormControl<CommunicationType | null>(communication?.type ?? null, {
                validators: [Validators.required, (control: AbstractControl) => this.getCommunicationTypeValidator(control)],
                nonNullable: true,
            }),
            linkType: new FormControl<CommunicationLinkType>(communication?.linkType ?? CommunicationLinkType.Unknown, {
                nonNullable: true,
            }),
            linkDelay: new FormControl<number | null>(communication?.linkDelay ?? null, {
                validators: [Validators.min(this.MIN_LINK_DELAY_VALUE), Validators.max(this.MAX_LINK_DELAY_VALUE)],
                nonNullable: true,
            }),
            transmissionLinkRange: new FormControl<number | null>(communication?.transmissionLinkRange ?? null, {
                validators: [
                    Validators.required,
                    Validators.min(this.MIN_TRANSMISSION_LINK_RANGE_VALUE),
                    Validators.max(this.MAX_TRANSMISSION_LINK_RANGE_VALUE),
                ],
                nonNullable: true,
            }),
            isEmbedded: new FormControl<boolean>(communication?.isEmbedded ?? false, {
                nonNullable: true,
            }),
            frequencies: new FormControl<CommunicationFrequency[]>(communication?.frequencies ?? [], {
                nonNullable: true,
            }),
        });

        communicationForm.controls.type.valueChanges.pipe(untilDestroyed(this)).subscribe((type) => {
            communicationForm.controls.frequencies.clearValidators();

            if (this.areFrequenciesRequired(type)) {
                communicationForm.controls.frequencies.setValidators(Validators.minLength(1));
            } else {
                communicationForm.controls.frequencies.setValue([], { emitEvent: false });
            }

            communicationForm.controls.frequencies.updateValueAndValidity();
        });

        communicationForm.controls.linkType.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            communicationForm.controls.linkDelay.setValue(null);
        });

        return communicationForm;
    }

    private getCommunicationTypeValidator(typeControl: AbstractControl): ValidationErrors | null {
        for (const communicationForm of this.communicationsArray.controls) {
            if (communicationForm.value.type === typeControl.value && communicationForm.controls.type !== typeControl) {
                return { notUnique: true };
            }
        }

        return null;
    }

    private confirmCommunicationRemoval(type: CommunicationType | null): Observable<boolean> {
        const confirmationText = type
            ? this.transloco.translate("dtmWebAppLibUav.setupForm.communications.removalConfirmationDialog.dialogMessageWithType", {
                  type: this.transloco.translate("dtmSharedUav.setupProperties.communicationProperties.typeValue", { value: type }),
              })
            : this.transloco.translate("dtmWebAppLibUav.setupForm.communications.removalConfirmationDialog.dialogMessage");

        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.setupForm.communications.removalConfirmationDialog.titleText"),
                    confirmationText,
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.setupForm.communications.removalConfirmationDialog.declineButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.setupForm.communications.removalConfirmationDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private removeCommunication(index: number): void {
        this.communicationsArray.removeAt(index);
        this.updateCommunicationsControls();
    }

    private updateCommunicationsControls() {
        this.communicationsControls$.next(this.communicationsArray.controls);
    }
}
