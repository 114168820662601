import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { UavClass } from "@dtm-frontend/shared/uav";
import { SelectFieldComponent, SerialNumbersControlComponent } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { startWith } from "rxjs/operators";
import { Manufacturer, ManufacturerModel } from "../../../../../services/uav.models";
import { StandardUavForm } from "../basic-info-step.component";

interface NewUavWizardBasicInfoStepStandardUavComponentState {
    uavModels: ManufacturerModel[];
    uavModelsOptions: ManufacturerModel[];
    uavModelImageUrl: string | undefined;
    isModelImageUrlProcessing: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-new-uav-wizard-basic-info-step-standard-uav[formGroup][uavModels]",
    templateUrl: "./standard-uav.component.html",
    styleUrls: ["./standard-uav.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NewUavWizardBasicInfoStepStandardUavComponent implements OnInit {
    @Input("formGroup") public standardUavForm!: FormGroup<StandardUavForm>;

    @Input() public set uavModels(value: ManufacturerModel[] | undefined) {
        const sortedUavModels = [...(value ?? [])].sort((left, right) => left.name.localeCompare(right.name));
        this.localStore.patchState({ uavModels: sortedUavModels, uavModelsOptions: sortedUavModels });

        if (!this.standardUavForm.controls.uavModel.value) {
            this.standardUavForm.controls.uavModel.setValue(value?.length === 1 ? value[0] : null);
        }

        this.uavModelSearchControl.reset("");
    }

    @ViewChild(SerialNumbersControlComponent) private readonly serialNumberControl!: SerialNumbersControlComponent;
    @ViewChild("uavModelSelect", { read: SelectFieldComponent }) private readonly uavModelSelect!: SelectFieldComponent;

    protected readonly uavModels$ = this.localStore.selectByKey("uavModelsOptions");
    protected readonly isModelImageUrlProcessing$ = this.localStore.selectByKey("isModelImageUrlProcessing");
    protected readonly uavModelImageUrl$ = this.localStore.selectByKey("uavModelImageUrl");

    protected readonly uavModelSearchControl = new FormControl<string>("", { nonNullable: true });
    protected readonly uavClasses: UavClass[] = Object.values(UavClass);

    constructor(private readonly localStore: LocalComponentStore<NewUavWizardBasicInfoStepStandardUavComponentState>) {
        this.localStore.setState({
            uavModels: [],
            uavModelsOptions: [],
            uavModelImageUrl: undefined,
            isModelImageUrlProcessing: false,
        });
    }

    public ngOnInit() {
        this.manageInitialData();
        this.watchManufacturerAndUavModelChanges();
        this.watchManufacturerChanges();
        this.watchUavModelChange();
        this.watchUavModelSearchValueChange();
    }

    public focusOnSerialNumberControl() {
        this.serialNumberControl.focus();
    }

    protected setModelImageUrlProcessing(isProcessing: boolean): void {
        this.localStore.patchState({ isModelImageUrlProcessing: isProcessing });
    }

    protected selectActiveUavModel() {
        const uavModelsOptions = this.localStore.selectSnapshotByKey("uavModelsOptions");
        let value = this.uavModelSelect.activeItem?.value;

        if (uavModelsOptions.length === 1) {
            value = uavModelsOptions[0];
        }

        if (!value || !uavModelsOptions.length) {
            return;
        }

        this.standardUavForm.controls.uavModel.setValue(value);
    }

    private manageInitialData() {
        const manufacturer = this.standardUavForm.value.manufacturer ?? undefined;
        const uavModel = this.standardUavForm.value.uavModel ?? undefined;
        const uavClasses = this.standardUavForm.value.uavClasses ?? [];
        const name = this.standardUavForm.controls.name.value;

        if (!name) {
            this.setDefaultName(manufacturer, uavModel);
        } else if (this.getSuggestedUavName(manufacturer, uavModel) !== name) {
            this.standardUavForm.controls.name.markAsDirty();
        }

        this.setUavModelImage(uavModel?.imageUrl);
        if (!uavClasses.length) {
            this.setUavClasses(uavModel?.uavClasses);
        }
    }

    private watchManufacturerAndUavModelChanges(): void {
        combineLatest([this.standardUavForm.controls.manufacturer.valueChanges, this.standardUavForm.controls.uavModel.valueChanges])
            .pipe(untilDestroyed(this))
            .subscribe(([manufacturer, uavModel]) => {
                this.setDefaultName(manufacturer ?? undefined, uavModel ?? undefined);
            });
    }

    private watchManufacturerChanges(): void {
        this.standardUavForm.controls.manufacturer.valueChanges.pipe(untilDestroyed(this)).subscribe((manufacturer) => {
            this.standardUavForm.controls.uavModel.setValue(manufacturer?.models.length === 1 ? manufacturer.models[0] : null);
        });
    }

    private watchUavModelChange() {
        this.standardUavForm.controls.uavModel.valueChanges.pipe(untilDestroyed(this)).subscribe((uavModel) => {
            this.setUavModelImage(uavModel?.imageUrl);
            this.setUavClasses(uavModel?.uavClasses);

            this.uavModelSearchControl.reset("");
        });
    }

    private watchUavModelSearchValueChange() {
        this.uavModelSearchControl.valueChanges.pipe(startWith(""), untilDestroyed(this)).subscribe((searchValue) => {
            this.localStore.patchState(({ uavModels }) => ({
                uavModelsOptions: uavModels.filter((uavModel) => uavModel.name.toLowerCase().includes(searchValue.toLowerCase())),
            }));
        });
    }

    private setDefaultName(manufacturer: Manufacturer | undefined, uavModel: ManufacturerModel | undefined) {
        if (this.standardUavForm.controls.name.dirty || !manufacturer) {
            return;
        }

        this.standardUavForm.controls.name.setValue(this.getSuggestedUavName(manufacturer, uavModel), { emitEvent: false });
        this.standardUavForm.controls.name.markAsPristine();
    }

    private setUavModelImage(uavModelImageUrl: string | undefined) {
        if (!uavModelImageUrl) {
            this.setModelImageUrlProcessing(false);
        }

        this.localStore.patchState({ uavModelImageUrl });
    }

    private setUavClasses(uavClasses: UavClass[] = []) {
        this.standardUavForm.controls.uavClasses.setValue(uavClasses, { emitEvent: false });
        this.standardUavForm.controls.uavClasses.markAsPristine();
    }

    private getSuggestedUavName(manufacturer: Manufacturer | undefined, uavModel: ManufacturerModel | undefined): string {
        let suggestedName = "";
        const isValidManufacturerModel = manufacturer?.models.some((model) => model.id === uavModel?.id);

        if (isValidManufacturerModel && uavModel) {
            suggestedName = `${manufacturer?.name} ${uavModel.name}`;
        }

        return suggestedName;
    }

    protected resetModelSearch() {
        // NOTE: setTimeout needed to prevent options from being re-created (in uavModelSearchControl.valueChanges subscription),
        // which clears the selection before it is handled
        setTimeout(() => this.uavModelSearchControl.reset(""));
    }
}
