import { MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS, TechnicalSpecification } from "@dtm-frontend/shared/uav";
import {
    DateUtils,
    ISO8601TimeDuration,
    Logger,
    METERS_IN_KILOMETER,
    SECONDS_IN_HOUR,
    SECONDS_IN_MINUTE,
} from "@dtm-frontend/shared/utils";

export interface EditableFlightTechnicalProperties {
    maxFlightTime: number;
    hasFlightSpeedLimit: boolean;
    maxFlightSpeed: number;
    minFlightSpeed?: number;
}

type FlightTechnicalSpecification = Pick<
    TechnicalSpecification,
    "maxFlightTime" | "hasFlightSpeedLimit" | "maxFlightSpeed" | "minFlightSpeed"
>;

export class FlightTechnicalPropertiesUtils {
    public static getFormValuesBySetupDefaults(
        technicalSpecification: FlightTechnicalSpecification
    ): EditableFlightTechnicalProperties | null {
        const minFlightSpeed = technicalSpecification.minFlightSpeed
            ? +FlightTechnicalPropertiesUtils.convertMetersPerSecondToKilometersPerHour(technicalSpecification.minFlightSpeed).toFixed(
                  MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS
              )
            : null;
        const maxFlightTime = technicalSpecification.maxFlightTime
            ? FlightTechnicalPropertiesUtils.convertDurationToMinutes(technicalSpecification.maxFlightTime)
            : null;

        let flightTechnicalProperties: EditableFlightTechnicalProperties | null = null;
        if (maxFlightTime) {
            flightTechnicalProperties = {
                maxFlightTime,
                hasFlightSpeedLimit: technicalSpecification.hasFlightSpeedLimit,
                maxFlightSpeed: +FlightTechnicalPropertiesUtils.convertMetersPerSecondToKilometersPerHour(
                    technicalSpecification.maxFlightSpeed
                ).toFixed(MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS),
                minFlightSpeed: minFlightSpeed ?? undefined,
            };
        }

        return flightTechnicalProperties;
    }

    public static prepareResultTechnicalSpecificationSetup(
        properties: EditableFlightTechnicalProperties,
        isMinFlightSpeedVisible: boolean
    ): FlightTechnicalSpecification {
        let minFlightSpeed;
        if (isMinFlightSpeedVisible) {
            minFlightSpeed = properties.minFlightSpeed
                ? FlightTechnicalPropertiesUtils.convertKilometersPerHourToMetersPerSecond(
                      +properties.minFlightSpeed.toFixed(MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS)
                  )
                : null;
        }

        return {
            maxFlightTime: DateUtils.convertSecondsToISO8601Duration(properties.maxFlightTime * SECONDS_IN_MINUTE),
            hasFlightSpeedLimit: properties.hasFlightSpeedLimit,
            maxFlightSpeed: FlightTechnicalPropertiesUtils.convertKilometersPerHourToMetersPerSecond(
                +properties.maxFlightSpeed.toFixed(MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS)
            ),
            minFlightSpeed,
        };
    }

    private static convertMetersPerSecondToKilometersPerHour(value: number) {
        return (value * SECONDS_IN_HOUR) / METERS_IN_KILOMETER;
    }

    private static convertKilometersPerHourToMetersPerSecond(value: number) {
        return (value * METERS_IN_KILOMETER) / SECONDS_IN_HOUR;
    }

    private static convertDurationToMinutes(maxFlightTime: ISO8601TimeDuration): number | null {
        const seconds = DateUtils.convertISO8601DurationToSeconds(maxFlightTime);

        if (seconds !== null) {
            return Math.round(seconds / SECONDS_IN_MINUTE);
        }

        Logger.captureMessage("FlightTechnicalPropertiesUtils.convertDurationToMinutes: cannot convert given duration to minutes", {
            level: "warning",
            extra: { maxFlightTime },
        });

        return null;
    }
}
