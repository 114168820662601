import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
    DialogService,
    GlobalOperatorPermissions,
    MIN_PAGE_SIZE_VALUE,
    OperatorType,
    PAGE_NUMBER_QUERY_PARAM,
    PAGE_SIZE_QUERY_PARAM,
} from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, combineLatest, of, switchMap } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { EditableCustomUav, EditableUav, ShareUavModel, SortParam, UavErrorType, UavListItem } from "../../services/uav.models";
import { UavActions } from "../../state/uav.actions";
import { UavState } from "../../state/uav.state";
import { EditCustomUavDialogComponent } from "../edit-custom-uav-dialog/edit-custom-uav-dialog.component";
import { EditUavDialogComponent } from "../edit-uav-dialog/edit-uav-dialog.component";
import { ShareUavSetupComponent } from "../share-uav-setup/share-uav-setup.component";
import { Sorting, UavListFilter } from "./uav-list-filters/uav-list-filters.component";

const TEXT_SEARCH_QUERY_PARAM = "textSearch";
const SORT_BY_QUERY_PARAM = "sortBy";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-list",
    templateUrl: "./uav-list.component.html",
    styleUrls: ["./uav-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UavListComponent implements OnInit {
    protected readonly uavs$ = this.store.select(UavState.uavList).pipe(RxjsUtils.filterFalsy());
    protected readonly canShareUavs$ = this.store.select(UavState.canShareUavs);
    protected readonly isOwnerVisible$ = this.uavs$.pipe(map((uavs) => this.shouldOwnerBeVisibleForUavsSet(uavs)));
    protected readonly canManageUavs$ = this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorUavsManage));
    protected readonly canReadUavs$ = this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorUavsRead));
    protected readonly pagination$ = this.store.select(UavState.uavListPages).pipe(RxjsUtils.filterFalsy());
    protected readonly error$ = this.store.select(UavState.uavListError);
    protected readonly isProcessing$ = this.store.select(UavState.isProcessing);
    protected readonly initialFilters$: Observable<UavListFilter> = this.route.queryParams.pipe(
        first(),
        map((queryParams) => ({
            textSearch: queryParams[TEXT_SEARCH_QUERY_PARAM] ?? "",
        }))
    );
    protected readonly initialSorting$: Observable<Sorting> = this.route.queryParams.pipe(
        first(),
        map((queryParams) => queryParams[SORT_BY_QUERY_PARAM])
    );
    protected readonly areFiltersApplied$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService,
        private readonly dialogService: DialogService
    ) {}

    public ngOnInit() {
        this.watchParamsChange();
    }

    protected refresh() {
        const operator = this.store.selectSnapshot(OperatorContextState.selectedContext);
        const params = this.route.snapshot.queryParams;

        if (!operator || !params) {
            return;
        }

        this.refreshList(operator.id, params);
    }

    protected changePage(event: PageEvent) {
        const params: Params = {
            [PAGE_NUMBER_QUERY_PARAM]: event?.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: event?.pageSize,
        };

        this.navigateByParams(params);
    }

    protected changeFilters(filters: UavListFilter) {
        const params: Params = {
            [TEXT_SEARCH_QUERY_PARAM]: filters.textSearch || null,
        };

        this.navigateByParams(params);
    }

    protected showDetails(uav: UavListItem) {
        this.router.navigate(["/uav", uav.id]);
    }

    protected redirectToUavWizard() {
        this.router.navigate(["/uav/new"]);
    }

    protected editUav(uav: UavListItem) {
        const editUav$: Observable<MatDialogRef<EditUavDialogComponent | EditCustomUavDialogComponent>> = uav.isCustom
            ? this.editCustomUav(uav)
            : this.editUavValues(uav);

        editUav$.pipe(untilDestroyed(this)).subscribe((dialogRef) => {
            const error = this.store.selectSnapshot(UavState.uavError);

            if (error?.type === UavErrorType.CannotUpdateUavSerialNumberExists) {
                this.toastService.error(this.transloco.translate("dtmWebAppLibUav.editUav.serialNumbersAlreadyExistsErrorMessage"));

                return;
            }

            if (error) {
                this.toastService.error(this.transloco.translate("dtmWebAppLibUav.editUav.genericErrorMessage"));

                return;
            }

            this.toastService.success(this.transloco.translate("dtmWebAppLibUav.editUav.successMessage"));

            this.refresh();
            if (this.store.selectSnapshot(UavState.currentUav)?.id === uav.id) {
                this.store.dispatch(new UavActions.GetUav(uav.id));
            }

            dialogRef.close();
        });
    }

    protected openShareUavDialog({ id, name }: UavListItem) {
        const isPilot = this.store.selectSnapshot(OperatorContextState.selectedContextType) === OperatorType.Personal;
        const dialogRef = this.dialogService.open(ShareUavSetupComponent, {
            data: { uavName: name, uavId: id, isPilot },
        });

        this.getCapabilities(dialogRef, id);
        this.tryShareConfigurations(dialogRef, id);
    }

    protected changeSorting(sortBy: Sorting) {
        const params: Params = {
            [SORT_BY_QUERY_PARAM]: sortBy,
        };

        this.navigateByParams(params);
    }

    protected removeUav(uav: UavListItem) {
        return this.store
            .dispatch(new UavActions.DeleteUav(uav.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UavState.uavError);

                if (error) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavRemoval.genericErrorMessage"));

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibUav.uavRemoval.successMessage"));
                this.refresh();
            });
    }

    private tryShareConfigurations(shareUavDialogRef: MatDialogRef<ShareUavSetupComponent>, uavId: string): void {
        shareUavDialogRef.componentInstance.shareSetup
            .pipe(
                switchMap((setupList: ShareUavModel) => this.store.dispatch(new UavActions.ShareUavSetups(uavId, setupList))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(UavState.shareUavSetupsError);

                if (error?.type === UavErrorType.UavAlreadySharedToOperator) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibUav.shareUav.uavAlreadySharedToOperatorErrorMessage"));

                    return;
                } else if (error) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibUav.shareUav.shareUavErrorMessage"));

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibUav.shareUav.shareUavSuccessMessage"));
                shareUavDialogRef.close();
            });
    }

    private getCapabilities(shareUavDialogRef: MatDialogRef<ShareUavSetupComponent>, uavId: string): void {
        shareUavDialogRef
            .afterOpened()
            .pipe(
                tap(() => this.store.dispatch(new UavActions.GetShareCapabilities(uavId))),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private watchParamsChange() {
        combineLatest([
            this.store.select(OperatorContextState.selectedContext).pipe(
                RxjsUtils.filterFalsy(),
                switchMap((operator, index) => {
                    if (index === 0) {
                        return of(operator.id);
                    }

                    return this.store.dispatch(new UavActions.ForceGetCapabilities()).pipe(map(() => operator.id));
                })
            ),
            this.route.queryParams,
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([operatorId, params]) => {
                this.refreshList(operatorId, params);
            });
    }

    private refreshList(operatorId: string, queryParams: Params) {
        this.store
            .dispatch(
                new UavActions.GetUavList({
                    operatorId,
                    size: queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIN_PAGE_SIZE_VALUE,
                    page: queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
                    textSearch: queryParams[TEXT_SEARCH_QUERY_PARAM] ?? undefined,
                    sortBy: this.getSortingParamValue(queryParams[SORT_BY_QUERY_PARAM] ?? Sorting.NameAsc),
                })
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.areFiltersApplied$.next(!!queryParams[TEXT_SEARCH_QUERY_PARAM]);
            });
    }

    private navigateByParams(params: Params) {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: {
                ...this.route.snapshot.queryParams,
                ...params,
            },
            replaceUrl: true,
        });
    }

    private shouldOwnerBeVisibleForUavsSet(uavs: UavListItem[]): boolean {
        return uavs.some((uav) => uav.isShared);
    }

    private getSortingParamValue(sortBy: Sorting): SortParam {
        switch (sortBy) {
            case Sorting.NameAsc:
                return "name,asc";
            case Sorting.NameDesc:
                return "name,desc";
        }
    }
    private editUavValues(uav: UavListItem): Observable<MatDialogRef<EditUavDialogComponent>> {
        const dialogRef = this.dialogService.open(EditUavDialogComponent, {
            data: {
                currentName: uav.name,
                currentSerialNumbers: uav.serialNumbers,
                currentUavClasses: uav.uavClasses,
                currentIsSwarm: uav.isSwarm,
                isProcessing$: this.isProcessing$,
            },
        });

        return dialogRef.componentInstance.setUavValues$.pipe(
            switchMap((values: EditableUav) => this.store.dispatch(new UavActions.UpdateUav(uav.id, values))),
            map(() => dialogRef)
        );
    }

    private editCustomUav(uav: UavListItem): Observable<MatDialogRef<EditCustomUavDialogComponent>> {
        const dialogRef = this.dialogService.open(EditCustomUavDialogComponent, {
            data: {
                currentName: uav.name,
                currentSerialNumbers: uav.serialNumbers,
                currentUavClasses: uav.uavClasses,
                currentIsSwarm: uav.isSwarm,
                currentManufacturerName: uav.manufacturerName,
                currentModelName: uav.modelName,
                currentType: uav.modelType,
                currentIsCeCompliant: uav.isCeCompliant,
                currentImageId: uav.imageId,
                isProcessing$: this.isProcessing$,
            },
        });

        return dialogRef.componentInstance.setUavValues$.pipe(
            switchMap((values: EditableCustomUav) => this.store.dispatch(new UavActions.UpdateCustomUav(uav.id, values))),
            map(() => dialogRef)
        );
    }
}
