import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, ViewChild, forwardRef } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { DriveType } from "@dtm-frontend/shared/uav";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, METERS_IN_KILOMETER, SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EditableCustomUavFlightTechnicalProperties } from "./custom-uav-flight-technical-properties-utils";

interface CustomUavFlightTechnicalPropertiesControlComponentState {
    isMinFlightSpeedVisible: boolean;
}

interface PropertiesForm {
    numberOfEngines: FormControl<number | null>;
    driveType: FormControl<DriveType | null>;
    minRecommendedAmbientTemperature: FormControl<number | null>;
    maxRecommendedAmbientTemperature: FormControl<number | null>;
    takeOffMass: FormControl<number | null>;
    maxTakeOffMass: FormControl<number | null>;
    maxDroneWidth: FormControl<number | null>;
    maxFlightTime: FormControl<number | null>;
    hasFlightSpeedLimit: FormControl<boolean>;
    maxFlightSpeed: FormControl<number | null>;
    minFlightSpeed: FormControl<number | null>;
    maxClimbSpeed: FormControl<number | null>;
    maxDescentSpeed: FormControl<number | null>;
    maxWind: FormControl<number | null>;
    maxFlightAltitude: FormControl<number | null>;
    hasRainFlightPossibility: FormControl<boolean>;
}

const MAX_NUMBER_OF_ENGINES_VALUE = 1000;
const MIN_TEMPERATURE_VALUE = -274;
const MAX_TEMPERATURE_VALUE = 2000;
const MIN_TAKE_OFF_MASS_VALUE = 0;
const MAX_TAKE_OFF_MASS_VALUE = 1000;
const MIN_MAX_TAKE_OFF_MASS_VALUE = 0;
const MAX_MAX_TAKE_OFF_MASS_VALUE = 1000;
const MAX_MAX_FLIGHT_TIME_VALUE_IN_SECONDS = 10e6;
const MAX_DRONE_WIDTH_VALUE = 4000;
const MAX_MIN_FLIGHT_SPEED_VALUE_IN_METERS_PER_SECOND = 1000;
const MAX_MAX_FLIGHT_SPEED_VALUE_IN_METERS_PER_SECOND = 1000;
const MIN_MAX_CLIMB_SPEED_VALUE = 0.001;
const MAX_MAX_CLIMB_SPEED_VALUE = 1000;
const MIN_MAX_DESCENT_SPEED_VALUE = 0.001;
const MAX_MAX_DESCENT_SPEED_VALUE = 1000;
const MIN_MAX_WIND_VALUE = 0;
const MAX_MAX_WIND_VALUE = 100;
const MIN_MAX_FLIGHT_ALTITUDE_VALUE = 0;
const MAX_MAX_FLIGHT_ALTITUDE_VALUE = 100 * METERS_IN_KILOMETER;
const MASS_WARNING_THRESHOLD = 100;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-custom-uav-flight-technical-properties-control",
    templateUrl: "./custom-uav-flight-technical-properties-control.component.html",
    styleUrls: ["../../setup-form-common.scss", "./custom-uav-flight-technical-properties-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomUavFlightTechnicalPropertiesControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CustomUavFlightTechnicalPropertiesControlComponent),
            multi: true,
        },
    ],
})
export class CustomUavFlightTechnicalPropertiesControlComponent implements ControlValueAccessor, Validator {
    protected readonly MIN_NUMBER_OF_ENGINES_VALUE = 1;
    protected readonly MAX_NUMBER_OF_ENGINES_VALUE = MAX_NUMBER_OF_ENGINES_VALUE;
    protected readonly MIN_TEMPERATURE_VALUE = MIN_TEMPERATURE_VALUE;
    protected readonly MAX_TEMPERATURE_VALUE = MAX_TEMPERATURE_VALUE;
    protected readonly MIN_TAKE_OFF_MASS_VALUE = MIN_TAKE_OFF_MASS_VALUE;
    protected readonly MAX_TAKE_OFF_MASS_VALUE = MAX_TAKE_OFF_MASS_VALUE;
    protected readonly MIN_MAX_TAKE_OFF_MASS_VALUE = MIN_MAX_TAKE_OFF_MASS_VALUE;
    protected readonly MAX_MAX_TAKE_OFF_MASS_VALUE = MAX_MAX_TAKE_OFF_MASS_VALUE;
    protected readonly MIN_MAX_FLIGHT_TIME_VALUE = 1;
    protected readonly MAX_MAX_FLIGHT_TIME_VALUE = Math.round(MAX_MAX_FLIGHT_TIME_VALUE_IN_SECONDS / SECONDS_IN_MINUTE);
    protected readonly MIN_DRONE_WIDTH_VALUE = 1;
    protected readonly MAX_DRONE_WIDTH_VALUE = MAX_DRONE_WIDTH_VALUE;
    protected readonly MIN_MAX_FLIGHT_SPEED_VALUE = 1;
    protected readonly MAX_MAX_FLIGHT_SPEED_VALUE =
        (MAX_MAX_FLIGHT_SPEED_VALUE_IN_METERS_PER_SECOND * SECONDS_IN_HOUR) / METERS_IN_KILOMETER;
    protected readonly MIN_MIN_FLIGHT_SPEED_VALUE = 1;
    protected readonly MAX_MIN_FLIGHT_SPEED_VALUE =
        (MAX_MIN_FLIGHT_SPEED_VALUE_IN_METERS_PER_SECOND * SECONDS_IN_HOUR) / METERS_IN_KILOMETER;
    protected readonly MIN_MAX_CLIMB_SPEED_VALUE = MIN_MAX_CLIMB_SPEED_VALUE;
    protected readonly MAX_MAX_CLIMB_SPEED_VALUE = MAX_MAX_CLIMB_SPEED_VALUE;
    protected readonly MIN_MAX_DESCENT_SPEED_VALUE = MIN_MAX_DESCENT_SPEED_VALUE;
    protected readonly MAX_MAX_DESCENT_SPEED_VALUE = MAX_MAX_DESCENT_SPEED_VALUE;
    protected readonly MIN_MAX_WIND_VALUE = MIN_MAX_WIND_VALUE;
    protected readonly MAX_MAX_WIND_VALUE = MAX_MAX_WIND_VALUE;
    protected readonly MIN_MAX_FLIGHT_ALTITUDE_VALUE = MIN_MAX_FLIGHT_ALTITUDE_VALUE;
    protected readonly MAX_MAX_FLIGHT_ALTITUDE_VALUE = MAX_MAX_FLIGHT_ALTITUDE_VALUE;
    protected readonly MASS_WARNING_THRESHOLD = MASS_WARNING_THRESHOLD;

    @Input() public set properties(value: EditableCustomUavFlightTechnicalProperties | null | undefined) {
        if (value === undefined) {
            return;
        }

        this.initPropertiesForm(value);
    }

    @Input() public set isMinFlightSpeedVisible(value: BooleanInput) {
        const isMinFlightSpeedVisible = coerceBooleanProperty(value);

        this.localStore.patchState({ isMinFlightSpeedVisible });

        this.manageMinFlightSpeedControl(isMinFlightSpeedVisible);
    }

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly propertiesForm = new FormGroup<PropertiesForm>(
        {
            numberOfEngines: new FormControl<number | null>(null, [
                Validators.min(this.MIN_NUMBER_OF_ENGINES_VALUE),
                Validators.max(this.MAX_NUMBER_OF_ENGINES_VALUE),
                this.getIntegerValidator(),
            ]),
            driveType: new FormControl<DriveType | null>(null, {
                nonNullable: true,
                validators: Validators.required,
            }),
            minRecommendedAmbientTemperature: new FormControl<number | null>(null, [
                Validators.min(this.MIN_TEMPERATURE_VALUE),
                Validators.max(this.MAX_TEMPERATURE_VALUE),
            ]),
            maxRecommendedAmbientTemperature: new FormControl<number | null>(null, [
                Validators.min(this.MIN_TEMPERATURE_VALUE),
                Validators.max(this.MAX_TEMPERATURE_VALUE),
            ]),
            takeOffMass: new FormControl<number | null>(null, {
                nonNullable: true,
                validators: [
                    Validators.required,
                    Validators.min(this.MIN_TAKE_OFF_MASS_VALUE),
                    Validators.max(this.MAX_TAKE_OFF_MASS_VALUE),
                ],
            }),
            maxTakeOffMass: new FormControl<number | null>(null, {
                nonNullable: true,
                validators: [
                    Validators.required,
                    Validators.min(this.MIN_MAX_TAKE_OFF_MASS_VALUE),
                    Validators.max(this.MAX_MAX_TAKE_OFF_MASS_VALUE),
                ],
            }),
            maxDroneWidth: new FormControl<number | null>(null, {
                nonNullable: true,
                validators: [Validators.required, Validators.min(this.MIN_DRONE_WIDTH_VALUE), Validators.max(this.MAX_DRONE_WIDTH_VALUE)],
            }),
            maxFlightTime: new FormControl<number | null>(null, {
                nonNullable: true,
                validators: [
                    Validators.required,
                    Validators.min(this.MIN_MAX_FLIGHT_TIME_VALUE),
                    Validators.max(this.MAX_MAX_FLIGHT_TIME_VALUE),
                ],
            }),
            hasFlightSpeedLimit: new FormControl<boolean>(false, { nonNullable: true }),
            minFlightSpeed: new FormControl<number | null>(null),
            maxFlightSpeed: new FormControl<number | null>(null, {
                nonNullable: true,
                validators: [
                    Validators.required,
                    Validators.min(this.MIN_MAX_FLIGHT_SPEED_VALUE),
                    Validators.max(this.MAX_MAX_FLIGHT_SPEED_VALUE),
                ],
            }),
            maxClimbSpeed: new FormControl<number | null>(null, [
                Validators.min(this.MIN_MAX_CLIMB_SPEED_VALUE),
                Validators.max(this.MAX_MAX_CLIMB_SPEED_VALUE),
            ]),
            maxDescentSpeed: new FormControl<number | null>(null, [
                Validators.min(this.MIN_MAX_DESCENT_SPEED_VALUE),
                Validators.max(this.MAX_MAX_DESCENT_SPEED_VALUE),
            ]),
            maxWind: new FormControl<number | null>(null, [
                Validators.min(this.MIN_MAX_WIND_VALUE),
                Validators.max(this.MAX_MAX_WIND_VALUE),
            ]),
            maxFlightAltitude: new FormControl<number | null>(null, [
                Validators.min(this.MIN_MAX_FLIGHT_ALTITUDE_VALUE),
                Validators.max(this.MAX_MAX_FLIGHT_ALTITUDE_VALUE),
            ]),
            hasRainFlightPossibility: new FormControl<boolean>(false, { nonNullable: true }),
        },
        this.getTemperatureRangeValidator()
    );
    protected readonly isMinFlightSpeedVisible$ = this.localStore.selectByKey("isMinFlightSpeedVisible");
    protected readonly driveTypes = Object.values(DriveType);

    private onTouched = FunctionUtils.noop;
    private propagateChange: (value: EditableCustomUavFlightTechnicalProperties | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<CustomUavFlightTechnicalPropertiesControlComponentState>) {
        this.localStore.setState({
            isMinFlightSpeedVisible: false,
        });

        this.propertiesForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.propagateChange(values as EditableCustomUavFlightTechnicalProperties);
        });
    }

    public registerOnChange(fn: (value: EditableCustomUavFlightTechnicalProperties | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: EditableCustomUavFlightTechnicalProperties | null | undefined): void {
        this.properties = value;
    }

    public validate(): ValidationErrors | null {
        if (this.propertiesForm.valid) {
            return null;
        }

        return { required: true };
    }

    public scrollToFirstInvalidField() {
        this.invalidFormScrollable.scrollToFirstInvalidField({
            behavior: "smooth",
            block: "center",
        });
    }

    private initPropertiesForm(properties: EditableCustomUavFlightTechnicalProperties | null): void {
        this.propertiesForm.setValue(
            {
                numberOfEngines: properties?.numberOfEngines ?? null,
                driveType: properties?.driveType ?? null,
                minRecommendedAmbientTemperature: properties?.minRecommendedAmbientTemperature ?? null,
                maxRecommendedAmbientTemperature: properties?.maxRecommendedAmbientTemperature ?? null,
                takeOffMass: properties?.takeOffMass ?? null,
                maxTakeOffMass: properties?.maxTakeOffMass ?? null,
                maxDroneWidth: properties?.maxDroneWidth ?? null,
                maxFlightTime: properties?.maxFlightTime ?? null,
                hasFlightSpeedLimit: properties?.hasFlightSpeedLimit ?? false,
                maxFlightSpeed: properties?.maxFlightSpeed ?? null,
                minFlightSpeed: properties?.minFlightSpeed ?? null ?? null,
                maxClimbSpeed: properties?.maxClimbSpeed ?? null,
                maxDescentSpeed: properties?.maxDescentSpeed ?? null,
                maxWind: properties?.maxWind ?? null,
                maxFlightAltitude: properties?.maxFlightAltitude ?? null,
                hasRainFlightPossibility: properties?.hasRainFlightPossibility ?? false,
            },
            { emitEvent: false }
        );
    }

    private manageMinFlightSpeedControl(isMinFlightSpeedVisible: boolean): void {
        if (isMinFlightSpeedVisible) {
            this.propertiesForm.controls.minFlightSpeed.setValidators([
                Validators.required,
                Validators.min(this.MIN_MIN_FLIGHT_SPEED_VALUE),
                Validators.max(this.MAX_MIN_FLIGHT_SPEED_VALUE),
            ]);
        } else {
            this.propertiesForm.controls.minFlightSpeed.clearValidators();
        }
        this.propertiesForm.controls.minFlightSpeed.setValue(null, { emitEvent: false });
    }

    private getIntegerValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = +control.value;
            const valueAsInteger = Math.floor(control.value);

            if (valueAsInteger === value) {
                return null;
            }

            return { integer: true };
        };
    }

    private getTemperatureRangeValidator(): ValidatorFn {
        return (): ValidationErrors | null => {
            if (!this.propertiesForm) {
                return null;
            }

            const minTemperature = this.propertiesForm.controls.minRecommendedAmbientTemperature.value;
            const maxTemperature = this.propertiesForm.controls.maxRecommendedAmbientTemperature.value;

            if (minTemperature === null || maxTemperature === null || minTemperature <= maxTemperature) {
                return null;
            }

            return { temperatureRange: true };
        };
    }
}
