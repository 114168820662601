import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { SerialNumbersDialogComponent } from "@dtm-frontend/shared/uav";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { UavListItem } from "../../../services/uav.models";

interface UavListItemComponentState {
    uav: UavListItem | undefined;
    isOwnerVisible: boolean;
    canShareUav: boolean;
    canManageUavs: boolean;
    isUavTitleTooltipDisabled: boolean;
    isManufacturerModelTooltipDisabled: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-list-item[uav][canShareUav]",
    templateUrl: "./uav-list-item.component.html",
    styleUrls: ["./uav-list-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavListItemComponent {
    protected readonly uav$ = this.localStore.selectByKey("uav").pipe(RxjsUtils.filterFalsy());
    protected readonly isOwnerVisible$ = this.localStore.selectByKey("isOwnerVisible");
    protected readonly canShareUav$ = this.localStore.selectByKey("canShareUav");
    protected readonly canManageUavs$ = this.localStore.selectByKey("canManageUavs");
    protected readonly isUavTitleTooltipDisabled$ = this.localStore.selectByKey("isUavTitleTooltipDisabled");
    protected readonly isManufacturerModelTooltipDisabled$ = this.localStore.selectByKey("isManufacturerModelTooltipDisabled");

    @Input() public set uav(value: UavListItem | undefined) {
        this.localStore.patchState({ uav: value });
    }

    @Input() public set isOwnerVisible(value: BooleanInput) {
        this.localStore.patchState({ isOwnerVisible: coerceBooleanProperty(value) });
    }

    @Input() public set canShareUav(value: BooleanInput) {
        this.localStore.patchState({ canShareUav: coerceBooleanProperty(value) });
    }

    @Input() public set canManageUavs(value: BooleanInput) {
        this.localStore.patchState({ canManageUavs: coerceBooleanProperty(value) });
    }

    @Output() public showDetails = new EventEmitter<UavListItem>();
    @Output() public edit = new EventEmitter<UavListItem>();
    @Output() public share = new EventEmitter<UavListItem>();
    @Output() public remove = new EventEmitter<UavListItem>();

    @ViewChild("uavTitle", { read: ElementRef }) protected set uavTitle(value: ElementRef<HTMLElement>) {
        this.localStore.patchState({ isUavTitleTooltipDisabled: value.nativeElement.scrollHeight <= value.nativeElement.offsetHeight });
    }

    @ViewChild("manufacturerModel", { read: ElementRef }) protected set manufacturerModel(value: ElementRef<HTMLElement>) {
        this.localStore.patchState({
            isManufacturerModelTooltipDisabled: value.nativeElement.scrollHeight <= value.nativeElement.offsetHeight,
        });
    }

    constructor(
        private readonly localStore: LocalComponentStore<UavListItemComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            uav: undefined,
            isOwnerVisible: false,
            canShareUav: false,
            canManageUavs: false,
            isUavTitleTooltipDisabled: false,
            isManufacturerModelTooltipDisabled: false,
        });
    }

    protected tryToRemoveUav(uav: UavListItem) {
        this.confirmUavRemoval(uav)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.remove.emit(uav));
    }

    protected showSerialNumbers(uav: UavListItem) {
        return this.dialogService.open(SerialNumbersDialogComponent, {
            data: {
                serialNumbers: uav.serialNumbers,
            },
        });
    }

    private confirmUavRemoval(uav: UavListItem): Observable<boolean> {
        const confirmationText = uav.isSwarm
            ? this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.swarmDialogMessage", {
                  uavName: uav.name,
                  uavSwarmSize: uav.serialNumbers.length,
              })
            : this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.dialogMessage", {
                  uavName: uav.name,
                  uavSerialNumber: uav.serialNumbers[0],
              });

        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.titleText"),
                    confirmationText,
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.declineButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }
}
