import { UavModelDocumentType } from "@dtm-frontend/shared/uav";
import { FilesGroup } from "@dtm-frontend/shared/ui";
import {
    EditableCustomUav,
    EditableCustomUavSetup,
    EditableUav,
    EditableUavSetup,
    NewCustomUav,
    NewUav,
    ShareUavModel,
    UavListParams,
} from "../services/uav.models";

export namespace UavActions {
    export class GetUavList {
        public static readonly type = "[UAV] Get UAV list";

        constructor(public params: UavListParams) {}
    }

    export class GetUav {
        public static readonly type = "[UAV] Get UAV";
        constructor(public uavId: string) {}
    }

    export class GetCapabilities {
        public static readonly type = "[UAV] Get Capabilities";
    }

    export class ForceGetCapabilities {
        public static readonly type = "[UAV] Force Get Capabilities";
    }

    export class GetManufacturersAndModels {
        public static readonly type = "[UAV] Get manufacturers and models";
    }

    export class GetUavModel {
        public static readonly type = "[UAV] Get UAV model";
        constructor(public uavModelId: string, public defaultCameraName: string) {}
    }

    export class MarkAsDirty {
        public static readonly type = "[UAV] Mark form as dirty (has unsaved changes)";

        constructor(public isDirty: boolean = true) {}
    }

    export class CreateUav {
        public static readonly type = "[UAV] Create UAV";
        constructor(public newUav: NewUav) {}
    }

    export class CreateCustomUav {
        public static readonly type = "[UAV] Create Custom UAV";
        constructor(public newUav: NewCustomUav) {}
    }

    export class DeleteUav {
        public static readonly type = "[UAV] Delete UAV";
        constructor(public uavId: string) {}
    }

    export class UpdateUav {
        public static readonly type = "[UAV] Update UAV";
        constructor(public uavId: string, public uavValues: EditableUav) {}
    }

    export class UpdateCustomUav {
        public static readonly type = "[UAV] Update Custom UAV";
        constructor(public uavId: string, public uavValues: EditableCustomUav) {}
    }

    export class CreateSetup {
        public static readonly type = "[UAV] Create UAV setup";
        constructor(public uavId: string, public setupValues: EditableUavSetup) {}
    }

    export class UpdateSetup {
        public static readonly type = "[UAV] Update UAV setup";
        constructor(
            public setupId: string,
            public uavId: string,
            public setupValues: EditableUavSetup | EditableCustomUavSetup,
            public isPrimarySetupOfCustomUav: boolean
        ) {}
    }

    export class DeleteSetup {
        public static readonly type = "[UAV] Delete UAV setup";
        constructor(public setupId: string, public uavId: string) {}
    }

    export class GetShareCapabilities {
        public static readonly type = "[UAV] Get share capabilities";
        constructor(public uavModelId: string) {}
    }

    export class ShareUavSetups {
        public static readonly type = "[UAV] Share UAV setups";
        constructor(public uavId: string, public shareUavModel: ShareUavModel) {}
    }

    export class AttachUavDocuments {
        public static readonly type = "[UAV] Attach UAV documents";
        constructor(public uavId: string, public documents: FilesGroup<UavModelDocumentType>[]) {}
    }
}
