<div class="grid grid-maxsize-8 container" *ngrxLet="isProcessing$ as isProcessing">
    <dtm-ui-wizard-step-wrapper
        [stepNumber]="1"
        [stepsAmount]="2"
        [title]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.header' | transloco"
        [isBackButtonVisible]="false"
        [isNextButtonEnabled]="isNextButtonEnabled$ | ngrxPush"
        [isNextStepProcessing]="isProcessing"
        (next)="goToNextStep()"
        dtmUiInvalidFormScrollable
    >
        <dtm-ui-loader-container [isShown]="isProcessing">
            <section class="basic-info-wrapper">
                <dtm-ui-select-field
                    [placeholder]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.manufacturerPlaceholder' | transloco"
                    [isClearable]="false"
                    [formControl]="manufacturerControl"
                    (panelClose)="resetManufacturerSearch()"
                    (openedChange)="manufacturerSearch?.focus()"
                    [shouldApplyOffsetTop]="true"
                    *ngrxLet="manufacturers$ as manufacturers"
                >
                    <label>{{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.manufacturerLabel" | transloco }}</label>
                    <dtm-ui-input-field class="manufacturer-search">
                        <input
                            #manufacturerSearch
                            matInput
                            [formControl]="manufacturerSearchControl"
                            [placeholder]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.manufacturerSearchLabel' | transloco"
                            (keydown.space)="$event.stopPropagation()"
                            (keydown.enter)="selectActiveManufacturer()"
                        />
                    </dtm-ui-input-field>
                    <dtm-ui-select-option *ngFor="let manufacturer of manufacturers" [value]="manufacturer">
                        {{ manufacturer.name }}
                    </dtm-ui-select-option>
                    <dtm-ui-select-option [value]="OTHER_MANUFACTURER_VALUE">
                        {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.otherManufacturerItemLabel" | transloco }}
                    </dtm-ui-select-option>
                    <div class="field-error" *dtmUiFieldHasError="manufacturerControl; name: 'required'">
                        {{ "dtmWebAppLibUav.newUavWizardSteps.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-select-field>

                <dtm-web-app-lib-new-uav-wizard-basic-info-step-standard-uav
                    *ngIf="isStandardUavModel$ | ngrxPush"
                    [formGroup]="standardUavForm"
                    [uavModels]="uavModels$ | ngrxPush"
                >
                </dtm-web-app-lib-new-uav-wizard-basic-info-step-standard-uav>
                <dtm-web-app-lib-new-uav-wizard-basic-info-step-custom-uav
                    *ngIf="isCustomUavModel$ | ngrxPush"
                    [formGroup]="customUavForm"
                    (isImageEditMode)="setImageEditMode($event)"
                >
                </dtm-web-app-lib-new-uav-wizard-basic-info-step-custom-uav>
            </section>
        </dtm-ui-loader-container>
    </dtm-ui-wizard-step-wrapper>
</div>
