import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DocumentsHelperService, Uav, UavModelDocumentType, UavSetup } from "@dtm-frontend/shared/uav";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, GlobalOperatorPermissions, OperatorType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, Logger, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, merge, skip, switchMap } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { EditableCustomUav, EditableUav, ShareUavModel, UavErrorType } from "../../services/uav.models";
import { SELECTED_SETUP_QUERY_PARAM_NAME } from "../../services/uav.resolvers";
import { UavActions } from "../../state/uav.actions";
import { UavState } from "../../state/uav.state";
import { EditCustomUavDialogComponent } from "../edit-custom-uav-dialog/edit-custom-uav-dialog.component";
import { EditUavDialogComponent } from "../edit-uav-dialog/edit-uav-dialog.component";
import { ManageUavDocumentsDialogComponent } from "../manage-uav-documents-dialog/manage-uav-documents-dialog.component";
import { ShareUavSetupComponent } from "../share-uav-setup/share-uav-setup.component";

interface UavCardComponentState {
    selectedSetup: UavSetup | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-card",
    templateUrl: "./uav-card.component.html",
    styleUrls: ["./uav-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavCardComponent implements OnInit {
    protected readonly UavErrorType = UavErrorType;
    protected readonly uav$ = this.store.select(UavState.currentUav).pipe(RxjsUtils.filterFalsy());
    protected readonly trackers$ = this.store.select(UavState.trackers).pipe(RxjsUtils.filterFalsy());
    protected readonly navigationAccuracyItems$ = this.store.select(UavState.navigationAccuracyItems);
    protected readonly canManageUavs$ = this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorUavsManage));
    protected readonly canReadUavs$ = this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorUavsRead));
    protected readonly isProcessing$ = this.store.select(UavState.isProcessing);
    protected readonly canShareUavs$ = this.store.select(UavState.canShareUavs);
    protected readonly error$ = merge(
        this.store.select(UavState.uavError).pipe(
            RxjsUtils.filterFalsy(),
            filter((error) => error?.type !== UavErrorType.CannotDeleteUavSetup)
        )
    );
    protected readonly selectedSetup$ = this.localStore.selectByKey("selectedSetup");
    protected readonly selectedSetupId$ = this.route.queryParams.pipe(map((params) => params[SELECTED_SETUP_QUERY_PARAM_NAME]));

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogService: DialogService,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<UavCardComponentState>,
        private readonly matDialog: MatDialog,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly documentsHelperService: DocumentsHelperService
    ) {
        this.localStore.setState({
            selectedSetup: undefined,
        });
    }

    public ngOnInit() {
        this.watchContextChange();
    }

    protected tryToRemoveUav(uav: Uav) {
        this.confirmUavRemoval(uav)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.removeUav(uav));
    }

    protected removeUav(uav: Uav) {
        this.store
            .dispatch(new UavActions.DeleteUav(uav.id))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(UavState.uavError);

                    if (error) {
                        this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavRemoval.genericErrorMessage"));

                        return;
                    }

                    this.onUavRemovalSuccessful();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected shareUav(uav: Uav) {
        const isPilot = this.store.selectSnapshot(OperatorContextState.selectedContextType) === OperatorType.Personal;
        const dialogRef = this.dialogService.open(ShareUavSetupComponent, {
            data: { uavName: uav.name, uavId: uav.id, isPilot },
        });

        this.getCapabilities(dialogRef, uav.id);
        this.tryShareConfigurations(dialogRef, uav.id);
    }

    protected editUav(uav: Uav) {
        const editUav$: Observable<MatDialogRef<EditUavDialogComponent | EditCustomUavDialogComponent>> = uav.model.isCustom
            ? this.editCustomUav(uav)
            : this.editUavValues(uav);

        editUav$.pipe(untilDestroyed(this)).subscribe((dialogRef) => {
            const error = this.store.selectSnapshot(UavState.uavError);

            if (error?.type === UavErrorType.CannotUpdateUavSerialNumberExists) {
                this.toastService.error(this.transloco.translate("dtmWebAppLibUav.editUav.serialNumbersAlreadyExistsErrorMessage"));

                return;
            }

            if (error) {
                this.toastService.error(this.transloco.translate("dtmWebAppLibUav.editUav.genericErrorMessage"));

                return;
            }

            this.toastService.success(this.transloco.translate("dtmWebAppLibUav.editUav.successMessage"));

            this.store.dispatch(new UavActions.GetUav(uav.id));
            dialogRef.close();
        });
    }

    protected manageUavDocuments(uav: Uav) {
        const manual = uav.documents[UavModelDocumentType.Manual];
        const projectDocumentation = uav.documents[UavModelDocumentType.ProjectDocumentation];

        const dialogRef = this.dialogService.open(ManageUavDocumentsDialogComponent, {
            data: {
                documents: this.documentsHelperService.convertUavDocumentFilesToUavModelDocumentsFilesGroups(manual, projectDocumentation),
                isProcessing$: this.isProcessing$,
            },
            disableClose: true,
        });

        dialogRef.componentInstance.setUavDocuments$
            .pipe(
                switchMap((resultDocuments) => this.store.dispatch(new UavActions.AttachUavDocuments(uav.id, resultDocuments))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(UavState.attachUavDocumentsError);

                if (error) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavDocuments.model.genericErrorMessage"));
                    dialogRef.close();

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibUav.uavDocuments.model.successMessage"));

                this.store.dispatch(new UavActions.GetUav(uav.id));
                dialogRef.close();
            });
    }

    protected addSetup(uav: Uav) {
        this.router.navigate(["/uav", uav.id, "add-setup"]);
    }

    protected tryToRemoveSetup(setup: UavSetup, uav: Uav) {
        this.confirmSetupRemoval(setup)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.removeSetup(setup, uav));
    }

    protected removeSetup(setup: UavSetup, uav: Uav) {
        this.store
            .dispatch([new UavActions.DeleteSetup(setup.id, uav.id)])
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(UavState.uavError);

                    if (error) {
                        this.toastService.error(this.transloco.translate("dtmWebAppLibUav.setupRemoval.genericErrorMessage"));

                        return;
                    }

                    this.onSetupRemovalSuccessful(uav);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected editSetup(setup: UavSetup, uav: Uav) {
        this.router.navigate(["/uav", uav.id, "edit-setup", setup.id]);
    }

    protected selectSetup(selectedSetup: UavSetup) {
        this.localStore.patchState({ selectedSetup });
    }

    private watchContextChange() {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(
                RxjsUtils.filterFalsy(),
                skip(1),
                switchMap(() => this.store.dispatch(new UavActions.ForceGetCapabilities())),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const uav = this.store.selectSnapshot(UavState.currentUav);

                if (!uav?.id) {
                    Logger.captureMessage("UavCardComponent.watchContextChange: currently loaded UAV is empty", {
                        level: "warning",
                        extra: { currentUav: uav },
                    });

                    return;
                }

                this.store.dispatch(new UavActions.GetUav(uav.id));
            });
    }

    private confirmSetupRemoval(setup: UavSetup): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.setupRemoval.confirmationDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibUav.setupRemoval.confirmationDialog.dialogMessage", {
                        setupName: setup.name,
                    }),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibUav.setupRemoval.confirmationDialog.declineButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibUav.setupRemoval.confirmationDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private onUavRemovalSuccessful() {
        const successMessage = this.transloco.translate("dtmWebAppLibUav.uavRemoval.successMessage");

        this.toastService.success(successMessage);
        this.router.navigate(["/uav", "list"]);
    }

    private onSetupRemovalSuccessful(uav: Uav) {
        const successMessage = this.transloco.translate("dtmWebAppLibUav.setupRemoval.successMessage");

        this.toastService.success(successMessage);
        this.store.dispatch(new UavActions.GetUav(uav.id));
    }

    private getCapabilities(shareUavDialogRef: MatDialogRef<ShareUavSetupComponent>, uavId: string): void {
        shareUavDialogRef
            .afterOpened()
            .pipe(
                tap(() => this.store.dispatch(new UavActions.GetShareCapabilities(uavId))),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private tryShareConfigurations(shareUavDialogRef: MatDialogRef<ShareUavSetupComponent>, uavId: string): void {
        shareUavDialogRef.componentInstance.shareSetup
            .pipe(
                switchMap((setupList: ShareUavModel) => this.store.dispatch(new UavActions.ShareUavSetups(uavId, setupList))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(UavState.shareUavSetupsError);

                if (error?.type === UavErrorType.UavAlreadySharedToOperator) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavCard.uavAlreadySharedToOperatorErrorMessage"));

                    return;
                } else if (error) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavCard.shareUavErrorMessage"));

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibUav.uavCard.shareUavSuccessMessage"));
                shareUavDialogRef.close();
            });
    }

    private editUavValues(uav: Uav): Observable<MatDialogRef<EditUavDialogComponent>> {
        const dialogRef = this.dialogService.open(EditUavDialogComponent, {
            data: {
                currentName: uav.name,
                currentSerialNumbers: uav.serialNumbers,
                currentUavClasses: uav.uavClasses,
                currentIsSwarm: uav.isSwarm,
                isProcessing$: this.isProcessing$,
            },
        });

        return dialogRef.componentInstance.setUavValues$.pipe(
            switchMap((values: EditableUav) => this.store.dispatch(new UavActions.UpdateUav(uav.id, values))),
            map(() => dialogRef)
        );
    }

    private editCustomUav(uav: Uav): Observable<MatDialogRef<EditCustomUavDialogComponent>> {
        const dialogRef = this.dialogService.open(EditCustomUavDialogComponent, {
            data: {
                currentName: uav.name,
                currentSerialNumbers: uav.serialNumbers,
                currentUavClasses: uav.uavClasses,
                currentIsSwarm: uav.isSwarm,
                currentManufacturerName: uav.model.manufacturer,
                currentModelName: uav.model.name,
                currentType: uav.model.type,
                currentIsCeCompliant: uav.isCeCompliant,
                currentImageId: uav.model.imageId,
                isProcessing$: this.isProcessing$,
            },
        });

        return dialogRef.componentInstance.setUavValues$.pipe(
            switchMap((values: EditableCustomUav) => this.store.dispatch(new UavActions.UpdateCustomUav(uav.id, values))),
            map(() => dialogRef)
        );
    }

    private confirmUavRemoval(uav: Uav): Observable<boolean> {
        const confirmationText = uav.isSwarm
            ? this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.swarmDialogMessage", {
                  uavName: uav.name,
                  uavSwarmSize: uav.serialNumbers.length,
              })
            : this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.dialogMessage", {
                  uavName: uav.name,
                  uavSerialNumber: uav.serialNumbers[0],
              });

        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.titleText"),
                    confirmationText,
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.declineButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }
}
