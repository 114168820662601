import {
    Communication,
    Equipment,
    NavigationAccuracy,
    Operator,
    TechnicalSpecification,
    Tracker,
    UavClass,
    UavProperties,
    UavSetup,
} from "@dtm-frontend/shared/uav";
import { Page, UavModel, UavType } from "@dtm-frontend/shared/ui";

export enum UavErrorType {
    Unknown = "UavErrorUnknown",
    NotFound = "UavErrorNotFound",
    CannotGetTrackers = "CannotGetTrackers",
    CannotGetCapabilities = "CannotGetCapabilities",
    CannotGetManufacturers = "CannotGetManufacturers",
    CannotGetModel = "CannotGetModel",
    CannotCreateUav = "CannotCreateUav",
    CannotCreateUavSerialNumberExists = "CannotCreateUavSerialNumberExists",
    CannotUpdateUavSerialNumberExists = "CannotUpdateUavSerialNumberExists",
    CannotGetUavs = "CannotGetUavs",
    CannotGetUav = "CannotGetUav",
    CannotGetSetup = "CannotGetSetup",
    CannotGetCapabilitiesForShareSetup = "CannotGetCapabilitiesForShareSetup",
    CannotShareSetupForOperator = "CannotShareSetupForOperator",
    CannotDeleteUav = "CannotDeleteUav",
    CannotUpdateUav = "CannotUpdateUav",
    CannotCreateUavSetup = "CannotCreateUavSetup",
    CannotUpdateUavSetup = "CannotUpdateUavSetup",
    CannotDeleteUavSetup = "CannotDeleteUavSetup",
    CannotAttachUavDocuments = "CannotAttachUavDocuments",
    UavAlreadySharedToOperator = "UavAlreadySharedToOperator",
}

export interface UavError {
    type: UavErrorType;
}

export type SortParam = `name,${"asc" | "desc"}`;

export const MAX_UAV_NAME_LENGTH = 100;
export const MIN_UAV_NAME_LENGTH = 2;

export interface UavListParams {
    operatorId: string;
    size: number;
    page: number;
    textSearch: string | undefined;
    sortBy: SortParam;
}

export interface UavListItem {
    id: string;
    name: string;
    manufacturerName: string;
    modelName: string;
    modelType: UavType;
    serialNumbers: string[];
    uavClasses: UavClass[];
    imageId?: string;
    imageUrl?: string;
    ownerName: string;
    isShared: boolean;
    isCustom: boolean;
    isSwarm: boolean;
    isCeCompliant: boolean;
}

export interface UavListWithPages {
    pages: Page;
    content: UavListItem[];
}

export interface UavCapabilities {
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
    canShareUavs: boolean;
}

export interface Manufacturer {
    name: string;
    models: ManufacturerModel[];
}

export interface ManufacturerModel {
    id: string;
    name: string;
    isCertificationRequired: boolean;
    uavClasses: UavClass[];
    imageUrl?: string;
}

export type ShareableOperator = Pick<Operator, "id" | "name">;

export interface EditableUavSetup extends Omit<UavSetup, "id" | "isPrimary" | "technicalSpecification" | "communications"> {
    id?: string;
    technicalSpecification: Pick<
        TechnicalSpecification,
        "maxFlightTime" | "hasFlightSpeedLimit" | "maxFlightSpeed" | "minFlightSpeed" | "failSafe" | "hasGeofencing" | "hasGeocage"
    >;
    communications: Communication[];
}

export interface EditableCustomUavSetup extends Omit<EditableUavSetup, "technicalSpecification"> {
    id?: string;
    technicalSpecification: TechnicalSpecification;
}

export interface EditableCustomUavInitialSetup extends Omit<EditableCustomUavSetup, "technicalSpecification" | "equipment"> {
    technicalSpecification: TechnicalSpecification | null;
    equipment: Equipment | null;
}

export interface UavModelProperties extends UavModel, Omit<UavProperties, "documents"> {
    uavClasses: UavClass[];
}

export type BasicUavSetup = Pick<UavSetup, "name" | "id" | "isPrimary">;

export interface NewUav {
    operatorId: string;
    name: string;
    isSwarm: boolean;
    serialNumbers: string[];
    uavClasses: UavClass[];
    manufacturer: Manufacturer;
    uavModel: ManufacturerModel;
    setup: EditableUavSetup;
}

export interface NewCustomUav {
    operatorId: string;
    name: string;
    isSwarm: boolean;
    serialNumbers: string[];
    uavClasses: UavClass[];
    isCeCompliant: boolean;
    manufacturerName: string;
    modelName: string;
    type: UavType;
    imageId: string | null;
    setup: EditableCustomUavSetup;
}

export interface CapabilitiesToShare {
    operators: ShareableOperator[];
    setups: BasicUavSetup[];
}

export interface ShareUavModel {
    receivingOperatorId: string;
    sharedSetups: string[];
}

export interface EditableUav {
    name: string;
    serialNumbers: string[];
    uavClasses: UavClass[];
}

export interface EditableCustomUav extends EditableUav {
    manufacturerName: string;
    modelName: string;
    type: UavType;
    isCeCompliant: boolean;
    imageId: string | null;
}
