import { Competency, DtmLocations, Page, PermitType } from "@dtm-frontend/shared/ui";

export enum OperatorPermitsErrorType {
    CannotGetSpecificPermitsList = "CannotGetSpecificPermitsList",
    CannotGetCapabilities = "CannotGetCapabilities",
    CannotGetPermitDetails = "CannotGetPermitDetails",
    CannotUpdateSpecificPermitName = "CannotUpdateSpecificPermitName",
    CannotGetKmlFile = "CannotGetKmlFile",
    CannotRemoveDraft = "CannotRemoveDraft",
}

export enum SpecificPermitApplicationDraftStatus {
    Accepted = "ACCEPTED",
    ToComplete = "TO_COMPLETE",
    Rejected = "REJECTED",
    Draft = "DRAFT",
    Pending = "PENDING",
}

export enum OwnedPermitStatus {
    Active = "ACTIVE",
    Suspended = "SUSPENDED",
    Withdrawn = "WITHDRAWN",
    Expired = "EXPIRED",
}

export interface OperatorPermitsError {
    type: OperatorPermitsErrorType;
}

export interface PermitsFilters {
    searchPhrase: string | null;
    pageNumber: number;
    pageSize: number;
}

export interface SpecificPermitDraftsListWithPages extends Page {
    content: SpecificPermitDraftsListItem[];
}

export interface OwnedSpecificPermitListWithPages extends Page {
    content: OwnedSpecificPermitListItem[];
}

export interface PermitListItem {
    id: string;
    number: string;
    type: PermitType;
    name: string;
    flightPurposes: string;
    operatorId: string;
    applicationId?: string;
    draftId?: string;
    createdAt: Date;
    validityPeriodStart: Date;
    validityPeriodFinish: Date;
}

export interface OwnedSpecificPermitListItem extends PermitListItem {
    status: OwnedPermitStatus;
    permitNumber: string;
}

export interface SpecificPermitDraftsListItem {
    id: string;
    name: string;
    operationName: string;
}

export interface OperatorPermitsCapabilities {
    dtmLocations: DtmLocations[];
    competencies: Competency[];
}

export interface SpecificPermitListQueryParams {
    pageNumber: number;
    pageSize: number;
    operatorId: string;
    status?: string[];
    searchPhrase?: string | null;
}
