<ng-container *ngrxLet="{ canManageUavs: canManageUavs$, canReadUavs: canReadUavs$, selectedSetup: selectedSetup$ } as vm">
    <ng-container *ngIf="vm.canReadUavs; else notAuthorizedTemplate">
        <div class="uav-nav">
            <button type="button" class="button-secondary" routerLink="../list">
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dtmWebAppLibUav.uavCard.goBackToListButtonLabel" | transloco }}
            </button>
        </div>
        <ng-container *ngIf="error$ | ngrxPush as error; else uavCardTemplate">
            <ng-container [ngSwitch]="error.type">
                <dtm-ui-error
                    *ngSwitchCase="UavErrorType.NotFound"
                    [errorMessage]="'dtmWebAppLibUav.uavCard.uavNotFoundErrorMessage' | transloco"
                ></dtm-ui-error>
                <dtm-ui-error
                    *ngSwitchCase="UavErrorType.CannotGetTrackers"
                    [errorMessage]="'dtmWebAppLibUav.uavCard.trackersNotFoundErrorMessage' | transloco"
                ></dtm-ui-error>
                <dtm-ui-error *ngSwitchDefault [errorMessage]="'dtmWebAppLibUav.uavCard.uavUnknownErrorMessage' | transloco"></dtm-ui-error>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #uavCardTemplate>
        <dtm-ui-loader-container *ngrxLet="uav$ as uav" [isShown]="isProcessing$ | ngrxPush">
            <div class="grid">
                <dtm-uav-lib-uav-header
                    dtmWebAppLibUavDocumentsApiProvider
                    [uav]="uav"
                    [isManageDocumentsButtonVisible]="uav.isCustom && vm.canManageUavs"
                    (documentsManage)="manageUavDocuments(uav)"
                    class="uav-header"
                >
                    <ng-container actionsSlot *ngIf="vm.canManageUavs">
                        <button [matMenuTriggerFor]="uavMenu" class="button-icon" type="button">
                            <dtm-ui-icon name="more" />
                        </button>
                        <mat-menu #uavMenu="matMenu" xPosition="before">
                            <button mat-menu-item (click)="editUav(uav)" type="button">
                                <dtm-ui-icon name="edit" />
                                <span>{{ "dtmWebAppLibUav.uavCard.uavActions.editUav" | transloco }}</span>
                            </button>
                            <button *ngIf="!uav.isShared && (canShareUavs$ | ngrxPush)" mat-menu-item (click)="shareUav(uav)" type="button">
                                <dtm-ui-icon name="share" />
                                <span>{{ "dtmWebAppLibUav.uavCard.uavActions.share" | transloco }}</span>
                            </button>
                            <button mat-menu-item (click)="tryToRemoveUav(uav)" type="button">
                                <dtm-ui-icon name="delete-bin" />
                                <span>{{ "dtmWebAppLibUav.uavCard.uavActions.delete" | transloco }}</span>
                            </button>
                        </mat-menu>
                    </ng-container>
                </dtm-uav-lib-uav-header>
                <dtm-uav-lib-uav-setups
                    dtmWebAppLibSetupDocumentsApiProvider
                    [setups]="uav.setups"
                    [trackers]="trackers$ | ngrxPush"
                    [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
                    [selectedSetupId]="selectedSetupId$ | ngrxPush"
                    (setupSelectionChange)="selectSetup($event)"
                    class="uav-setups"
                >
                    <ng-container headerSideSlot>
                        <button *ngIf="vm.canManageUavs" class="button-primary" type="button" (click)="addSetup(uav)">
                            <dtm-ui-icon name="add" />
                            {{ "dtmWebAppLibUav.uavCard.setupActions.addButtonLabel" | transloco }}
                        </button>
                    </ng-container>
                    <ng-container setupsListSideSlot>
                        <button
                            *ngIf="vm.canManageUavs && vm.selectedSetup"
                            class="button-secondary"
                            type="button"
                            (click)="editSetup(vm.selectedSetup, uav)"
                        >
                            <dtm-ui-icon name="edit" />
                            {{ "dtmWebAppLibUav.uavCard.setupActions.editButtonLabel" | transloco }}
                        </button>
                        <button
                            *ngIf="vm.selectedSetup && !vm.selectedSetup?.isPrimary && vm.canManageUavs"
                            class="button-secondary"
                            type="button"
                            (click)="tryToRemoveSetup(vm.selectedSetup, uav)"
                        >
                            <dtm-ui-icon name="delete-bin" />
                            {{ "dtmWebAppLibUav.uavCard.setupActions.removeButtonLabel" | transloco }}
                        </button>
                    </ng-container>
                    <ng-container emptyEquipmentMessageSlot *ngIf="!vm.canManageUavs">
                        {{ "dtmWebAppLibUav.uavCard.emptyEquipmentMessageLabel" | transloco }}
                    </ng-container>
                </dtm-uav-lib-uav-setups>
            </div>
        </dtm-ui-loader-container>
    </ng-template>
</ng-container>

<ng-template #notAuthorizedTemplate>
    <dtm-ui-not-authorized></dtm-ui-not-authorized>
</ng-template>
