import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
    CommunicationType,
    DocumentsHelperService,
    Equipment,
    EquipmentType,
    NavigationAccuracy,
    TechnicalSpecification,
    Tracker,
    UavSetupDocumentType,
} from "@dtm-frontend/shared/uav";
import { FilesGroup, UavModel, UavType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, Logger, ONLY_WHITE_SPACES_VALIDATION_PATTERN, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged, first, map } from "rxjs/operators";
import { EditableUavSetup } from "../../../services/uav.models";
import { AccessoriesControlComponent } from "./accessories-control/accessories-control.component";
import { Accessories, AccessoriesUtils } from "./accessories-control/accessories-utils";
import { CommunicationsControlComponent } from "./communications-control/communications-control.component";
import { CommunicationsUtils, EditableCommunication } from "./communications-control/communications-utils";
import { FlightTechnicalPropertiesControlComponent } from "./flight-technical-properties-control/flight-technical-properties-control.component";
import {
    EditableFlightTechnicalProperties,
    FlightTechnicalPropertiesUtils,
} from "./flight-technical-properties-control/flight-technical-properties-utils";
import { GeneralTechnicalPropertiesControlComponent } from "./general-technical-properties-control/general-technical-properties-control.component";
import {
    EditableGeneralTechnicalProperties,
    GeneralTechnicalPropertiesUtils,
} from "./general-technical-properties-control/general-technical-properties-utils";

interface SetupFormComponentState {
    setupDefaults: EditableUavSetup | undefined;
    isNameDisabled: boolean;
    uavModel: UavModel | undefined;
    isMinFlightSpeedVisible: boolean;
    factoryCommunicationTypes: CommunicationType[];
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
    uavTechnicalSpecification: TechnicalSpecification | undefined;
}

interface SetupFormValues {
    id: string | null;
    name: string;
    flightTechnicalProperties: EditableFlightTechnicalProperties;
    generalTechnicalProperties: EditableGeneralTechnicalProperties;
    communications: EditableCommunication[];
    documents: FilesGroup<UavSetupDocumentType>[];
    accessories: Accessories;
}

interface SetupForm {
    id: FormControl<string | null>;
    name: FormControl<string>;
    flightTechnicalProperties: FormControl<EditableFlightTechnicalProperties | null>;
    generalTechnicalProperties: FormControl<EditableGeneralTechnicalProperties | null>;
    communications: FormControl<EditableCommunication[]>;
    documents: FormControl<FilesGroup<UavSetupDocumentType>[]>;
    accessories: FormControl<Accessories>;
}

const SETUP_NAME_MAX_LENGTH = 100;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-setup-form[setupDefaults][uavModel][trackers][navigationAccuracyItems]",
    templateUrl: "./setup-form.component.html",
    styleUrls: ["./setup-form.component.scss", "./setup-form-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SetupFormComponent {
    protected readonly EquipmentType = EquipmentType;

    @Input() public set setupDefaults(value: EditableUavSetup | undefined) {
        this.localStore.patchState({ setupDefaults: value });
    }

    @Input() public set isNameDisabled(value: boolean | undefined) {
        if (value) {
            this.setupForm.controls.name.disable({ emitEvent: false });
        } else {
            this.setupForm.controls.name.enable({ emitEvent: false });
        }
    }

    @Input() public set uavModel(value: UavModel | undefined) {
        this.localStore.patchState({
            uavModel: value,
            isMinFlightSpeedVisible: value ? this.isMinFlightSpeedVisible(value) : false,
        });
    }

    @Input() public set factoryCommunicationTypes(value: CommunicationType[] | undefined) {
        this.localStore.patchState({ factoryCommunicationTypes: value ?? [] });
    }

    @Input() public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value ?? [] });
    }

    @Input() public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    @Input() public set uavTechnicalSpecification(value: TechnicalSpecification | undefined) {
        this.localStore.patchState({ uavTechnicalSpecification: value });
    }

    @Output() public setupChange = new EventEmitter<EditableUavSetup | undefined>();

    @ViewChild(FlightTechnicalPropertiesControlComponent)
    private flightTechnicalPropertiesControl!: FlightTechnicalPropertiesControlComponent;
    @ViewChild(GeneralTechnicalPropertiesControlComponent)
    private generalTechnicalPropertiesControl!: GeneralTechnicalPropertiesControlComponent;
    @ViewChild(CommunicationsControlComponent) private communicationsControl!: CommunicationsControlComponent;
    @ViewChild(AccessoriesControlComponent) private accessoriesControl!: AccessoriesControlComponent;

    protected readonly setupForm = this.initSetupForm();
    protected readonly isMinFlightSpeedVisible$ = this.localStore.selectByKey("isMinFlightSpeedVisible");
    protected readonly factoryCommunicationTypes$ = this.localStore.selectByKey("factoryCommunicationTypes");
    protected readonly trackers$ = this.localStore.selectByKey("trackers");
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly uavTechnicalSpecification$ = this.localStore.selectByKey("uavTechnicalSpecification");

    constructor(
        private readonly localStore: LocalComponentStore<SetupFormComponentState>,
        private readonly setupDocumentsService: DocumentsHelperService
    ) {
        this.localStore.setState({
            setupDefaults: undefined,
            isNameDisabled: false,
            uavModel: undefined,
            isMinFlightSpeedVisible: false,
            factoryCommunicationTypes: [],
            trackers: [],
            navigationAccuracyItems: [],
            uavTechnicalSpecification: undefined,
        });

        this.watchSetupChange();
    }

    public initSetupFormValues() {
        this.localStore
            .selectByKey("setupDefaults")
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe((setupDefaults) => {
                this.initFormValues(setupDefaults);
            });
    }

    public markInvalidField() {
        this.setupForm.markAllAsTouched();

        if (this.setupForm.controls.flightTechnicalProperties.invalid) {
            this.flightTechnicalPropertiesControl.scrollToFirstInvalidField();
        } else if (this.setupForm.controls.generalTechnicalProperties.invalid) {
            this.generalTechnicalPropertiesControl.scrollToFirstInvalidField();
        } else if (this.setupForm.controls.communications.invalid) {
            this.communicationsControl.scrollToFirstInvalidField();
        } else if (this.setupForm.controls.accessories.invalid) {
            this.accessoriesControl.scrollToFirstInvalidField();
        }
    }

    private watchSetupChange(): void {
        this.setupForm.valueChanges
            .pipe(
                map((formValues) => ({ isFormValid: this.setupForm.valid, formValues })),
                distinctUntilChanged(equal),
                untilDestroyed(this)
            )
            .subscribe(({ formValues }) => {
                this.setupChange.emit(this.setupForm.valid ? this.prepareResultSetup(formValues as SetupFormValues) : undefined);
            });
    }

    private initSetupForm() {
        return new FormGroup<SetupForm>({
            id: new FormControl<string | null>(null),
            name: new FormControl<string>("", {
                validators: [
                    Validators.required,
                    Validators.maxLength(SETUP_NAME_MAX_LENGTH),
                    Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                ],
                nonNullable: true,
            }),
            flightTechnicalProperties: new FormControl<EditableFlightTechnicalProperties | null>(null, {
                validators: Validators.required,
                nonNullable: true,
            }),
            generalTechnicalProperties: new FormControl<EditableGeneralTechnicalProperties | null>(null, {
                validators: Validators.required,
                nonNullable: true,
            }),
            communications: new FormControl<EditableCommunication[]>([], {
                nonNullable: true,
            }),
            documents: new FormControl<FilesGroup<UavSetupDocumentType>[]>([], {
                nonNullable: true,
            }),
            accessories: new FormControl<Accessories>(
                {
                    equipment: [],
                    parachuteEquipment: null,
                    trackings: [],
                },
                {
                    nonNullable: true,
                }
            ),
        });
    }

    private initFormValues(setupDefaults: EditableUavSetup) {
        this.setupForm.setValue(
            {
                id: setupDefaults.id ?? null,
                name: setupDefaults.name,
                flightTechnicalProperties: FlightTechnicalPropertiesUtils.getFormValuesBySetupDefaults(
                    setupDefaults.technicalSpecification
                ),
                generalTechnicalProperties: GeneralTechnicalPropertiesUtils.getFormValuesBySetupDefaults(
                    setupDefaults.technicalSpecification
                ),
                communications: CommunicationsUtils.getFormValuesBySetupDefaults(setupDefaults.communications),
                documents: this.setupDocumentsService.convertUavSetupDocumentsToSetupDocumentsFilesGroups(setupDefaults.documents),
                accessories: AccessoriesUtils.getFormValuesBySetupDefaults(setupDefaults),
            },
            { emitEvent: false }
        );
    }

    private prepareResultSetup(formValues: SetupFormValues): EditableUavSetup | undefined {
        const setupDefaults = this.localStore.selectSnapshotByKey("setupDefaults");
        if (!setupDefaults) {
            Logger.captureMessage("SetupFormComponent.prepareResultSetup: setup defaults are empty", {
                level: "warning",
                extra: { setupDefaults },
            });

            return undefined;
        }

        const isMinFlightSpeedVisible = this.localStore.selectSnapshotByKey("isMinFlightSpeedVisible");

        const result: EditableUavSetup = {
            name: this.setupForm.controls.name.disabled ? this.setupForm.controls.name.value : formValues.name,
            technicalSpecification: {
                ...FlightTechnicalPropertiesUtils.prepareResultTechnicalSpecificationSetup(
                    formValues.flightTechnicalProperties,
                    isMinFlightSpeedVisible
                ),
                ...GeneralTechnicalPropertiesUtils.prepareResultTechnicalSpecificationSetup(formValues.generalTechnicalProperties),
            },
            communications: CommunicationsUtils.prepareResultCommunicationsSetup(formValues.communications),
            documents: this.setupDocumentsService.convertSetupDocumentsFilesGroupsToUavSetupDocuments(formValues.documents),
            equipment: AccessoriesUtils.prepareResultEquipmentSetup(formValues.accessories),
            trackings: AccessoriesUtils.prepareResultTrackingSetup(formValues.accessories.trackings),
        };

        if (formValues.id) {
            result.id = formValues.id;
        }

        return result;
    }

    private isMinFlightSpeedVisible(uavModel: UavModel) {
        return uavModel.type === UavType.Airplane;
    }

    private mergeEmbeddedAndCustomEquipment(equipment: Equipment, customEquipment: Equipment): Equipment {
        return {
            camera: [...equipment.camera.filter((item) => item.isEmbedded), ...customEquipment.camera],
            propellersGuards: [...equipment.propellersGuards.filter((item) => item.isEmbedded), ...customEquipment.propellersGuards],
            navigationLighting: [...equipment.navigationLighting.filter((item) => item.isEmbedded), ...customEquipment.navigationLighting],
            nightLighting: [...equipment.nightLighting.filter((item) => item.isEmbedded), ...customEquipment.nightLighting],
            strobeLighting: [...equipment.strobeLighting.filter((item) => item.isEmbedded), ...customEquipment.strobeLighting],
            fts: [...equipment.fts.filter((item) => item.isEmbedded), ...customEquipment.fts],
            parachute: [...equipment.parachute.filter((item) => item.isEmbedded), ...customEquipment.parachute],
        };
    }
}
