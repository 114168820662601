import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UavModelDocumentType } from "@dtm-frontend/shared/uav";
import { FILES_UPLOAD_API_PROVIDER, FilesGroup } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";
import { UavDocumentUploadApiService } from "../../services/uav-document-upload-api.service";

interface ManageUavDocumentsDialogComponentState {
    isSaveButtonDisabled: boolean;
}

interface ManageUavDocumentsDialogData {
    documents: FilesGroup<UavModelDocumentType>[];
    isProcessing$: Observable<boolean>;
}

interface UavDocumentsForm {
    uavDocuments: FormControl<FilesGroup<UavModelDocumentType>[]>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-manage-uav-documents-dialog",
    templateUrl: "./manage-uav-documents-dialog.component.html",
    styleUrls: ["./manage-uav-documents-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: UavDocumentUploadApiService,
        },
    ],
})
export class ManageUavDocumentsDialogComponent {
    private readonly setUavDocumentsSubject = new Subject<FilesGroup<UavModelDocumentType>[]>();
    public readonly setUavDocuments$ = this.setUavDocumentsSubject.asObservable();
    protected readonly isSaveButtonDisabled$ = this.localStore.selectByKey("isSaveButtonDisabled");

    protected readonly uavDocumentsForm = new FormGroup<UavDocumentsForm>({
        uavDocuments: new FormControl<FilesGroup<UavModelDocumentType>[]>([], { nonNullable: true }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: ManageUavDocumentsDialogData,
        private readonly localStore: LocalComponentStore<ManageUavDocumentsDialogComponentState>
    ) {
        this.localStore.setState({ isSaveButtonDisabled: true });

        this.uavDocumentsForm.setValue({ uavDocuments: data.documents ?? [] }, { emitEvent: false });
        this.uavDocumentsForm.controls.uavDocuments.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.localStore.patchState({ isSaveButtonDisabled: false });
        });
    }

    protected save(): void {
        if (this.uavDocumentsForm.invalid) {
            this.uavDocumentsForm.markAllAsTouched();

            return;
        }

        this.setUavDocumentsSubject.next(this.uavDocumentsForm.getRawValue().uavDocuments);
    }
}
