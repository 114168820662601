import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewChild } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from "@angular/forms";
import { FailSafe } from "@dtm-frontend/shared/uav";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EditableGeneralTechnicalProperties } from "./general-technical-properties-utils";

interface PropertiesForm {
    failSafe: FormControl<FailSafe[]>;
    hasGeofencing: FormControl<boolean>;
    hasGeocage: FormControl<boolean>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-general-technical-properties-control",
    templateUrl: "./general-technical-properties-control.component.html",
    styleUrls: ["../setup-form-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GeneralTechnicalPropertiesControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => GeneralTechnicalPropertiesControlComponent),
            multi: true,
        },
    ],
})
export class GeneralTechnicalPropertiesControlComponent implements ControlValueAccessor, Validator {
    @Input() public set properties(value: EditableGeneralTechnicalProperties | undefined) {
        if (!value) {
            return;
        }

        this.initPropertiesForm(value);
    }

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly failSafeOptions = Object.values(FailSafe);
    protected readonly propertiesForm = new FormGroup<PropertiesForm>({
        failSafe: new FormControl<FailSafe[]>([], { nonNullable: true }),
        hasGeofencing: new FormControl<boolean>(false, { nonNullable: true }),
        hasGeocage: new FormControl<boolean>(false, { nonNullable: true }),
    });

    private onTouched = FunctionUtils.noop;
    private propagateChange: (value: EditableGeneralTechnicalProperties | null) => void = FunctionUtils.noop;

    constructor() {
        this.propertiesForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.propagateChange({
                failSafe: values.failSafe ?? [],
                hasGeofencing: values.hasGeofencing ?? false,
                hasGeocage: values.hasGeocage ?? false,
            });
        });
    }

    public registerOnChange(fn: (value: EditableGeneralTechnicalProperties | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: EditableGeneralTechnicalProperties | undefined): void {
        this.properties = value;
    }

    public validate(): ValidationErrors | null {
        if (this.propertiesForm.valid) {
            return null;
        }

        return { required: true };
    }

    public scrollToFirstInvalidField() {
        this.invalidFormScrollable.scrollToFirstInvalidField({
            behavior: "smooth",
            block: "center",
        });
    }

    private initPropertiesForm(properties: EditableGeneralTechnicalProperties): void {
        this.propertiesForm.setValue(
            {
                failSafe: properties.failSafe,
                hasGeofencing: properties.hasGeofencing,
                hasGeocage: properties.hasGeocage,
            },
            { emitEvent: false }
        );
    }
}
