import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { CommunicationType, Uav, UavSetup } from "@dtm-frontend/shared/uav";
import { ErrorMode, FILES_UPLOAD_API_PROVIDER, GlobalOperatorPermissions } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, combineLatest, lastValueFrom, merge, switchMap } from "rxjs";
import { map, tap } from "rxjs/operators";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { SetupDocumentUploadApiService } from "../../../services/setup-document-upload-api.service";
import { EditableCustomUavSetup, EditableUavSetup, UavErrorType } from "../../../services/uav.models";
import { SELECTED_SETUP_QUERY_PARAM_NAME, SETUP_ID_ROUTE_PARAM_NAME } from "../../../services/uav.resolvers";
import { UavActions } from "../../../state/uav.actions";
import { UavState } from "../../../state/uav.state";
import { CustomUavSetupFormComponent } from "../setup-form/custom-uav-setup-form/custom-uav-setup-form.component";
import { SetupFormComponent } from "../setup-form/setup-form.component";

interface NewUavSetupComponentState {
    setupValues: EditableUavSetup | EditableCustomUavSetup | undefined;
    isEditMode: boolean;
    isPrimarySetupPreviewVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-setup-editor",
    templateUrl: "./setup-editor.component.html",
    styleUrls: ["./setup-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: SetupDocumentUploadApiService,
        },
    ],
    animations: [AnimationUtils.foldAnimation()],
})
export class SetupEditorComponent implements AfterViewInit {
    protected readonly UavErrorType = UavErrorType;
    protected readonly ErrorMode = ErrorMode;
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");

    @ViewChild(SetupFormComponent) protected setupForm: SetupFormComponent | undefined;
    @ViewChild(CustomUavSetupFormComponent) protected customUavSetupForm: CustomUavSetupFormComponent | undefined;

    protected readonly uav$ = this.store.select(UavState.currentUav).pipe(RxjsUtils.filterFalsy());
    protected readonly canManageUav$ = this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorUavsManage));
    protected readonly primarySetup$ = this.initPrimarySetup();
    protected readonly setupDefaults$ = this.initSetupDefaults();
    protected readonly customUavSetupDefaults$ = this.initCustomUavSetupDefaults();
    protected readonly selectedSetup$ = this.uav$.pipe(
        RxjsUtils.filterFalsy(),
        map((uav) => this.getSelectedSetup(uav))
    );
    protected readonly isPrimarySetupSelected$ = this.selectedSetup$.pipe(map((setup) => !!setup?.isPrimary));

    protected readonly factoryCommunicationTypes$ = this.initFactoryCommunicationTypes();
    protected readonly trackers$ = this.store.select(UavState.trackers).pipe(RxjsUtils.filterFalsy());
    protected readonly navigationAccuracyItems$ = this.store.select(UavState.navigationAccuracyItems);
    protected readonly error$ = merge(
        this.store.select(UavState.uavError).pipe(RxjsUtils.filterFalsy()),
        combineLatest([this.selectedSetup$, this.isEditMode$]).pipe(
            map(([setup, isEditMode]) => (isEditMode && !setup ? { type: UavErrorType.CannotGetSetup } : undefined)),
            RxjsUtils.filterFalsy()
        )
    );
    protected readonly isProcessing$ = this.store.select(UavState.isProcessing);
    protected readonly isPrimarySetupPreviewVisible$ = this.localStore.selectByKey("isPrimarySetupPreviewVisible");
    protected readonly uavTechnicalSpecification$ = combineLatest([this.selectedSetup$, this.primarySetup$]).pipe(
        map(([selectedSetup, primarySetup]) => selectedSetup ?? primarySetup),
        map((setup) => setup?.technicalSpecification)
    );

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<NewUavSetupComponentState>,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService
    ) {
        this.localStore.setState({
            setupValues: undefined,
            isEditMode: this.route.snapshot.data.isEditMode ?? false,
            isPrimarySetupPreviewVisible: false,
        });
    }

    public ngAfterViewInit() {
        this.setupForm?.initSetupFormValues();
        this.customUavSetupForm?.initSetupFormValues();
    }

    protected setSetupValues(setup: EditableUavSetup | EditableCustomUavSetup | undefined) {
        this.localStore.patchState({ setupValues: setup });
    }

    protected openPrimarySetupPreview() {
        this.localStore.patchState({ isPrimarySetupPreviewVisible: true });
    }

    protected closePrimarySetupPreview() {
        this.localStore.patchState({ isPrimarySetupPreviewVisible: false });
    }

    protected saveSetup() {
        const uav = this.store.selectSnapshot(UavState.currentUav);
        const setupValues = this.localStore.selectSnapshotByKey("setupValues");

        if (!uav || !setupValues) {
            this.setupForm?.markInvalidField();
            this.customUavSetupForm?.markInvalidField();

            return;
        }

        if (this.localStore.selectSnapshotByKey("isEditMode")) {
            const selectedSetup = this.getSelectedSetup(uav);
            if (!selectedSetup) {
                return;
            }

            const isPrimarySetupOfCustomUav = uav.model.isCustom && selectedSetup.isPrimary;
            this.updateSetup(uav, setupValues, isPrimarySetupOfCustomUav);
        } else {
            this.createSetup(uav, setupValues);
        }
    }

    private createSetup(uav: Uav, setupValues: EditableUavSetup) {
        this.store
            .dispatch(new UavActions.CreateSetup(uav.id, setupValues))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(UavState.uavError);

                    if (error) {
                        this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavSetupEditor.setupCreatedErrorMessage"));

                        return;
                    }

                    this.toastService.success(
                        this.transloco.translate("dtmWebAppLibUav.uavSetupEditor.setupCreatedSuccessMessage", {
                            uavName: uav.name,
                            setupName: setupValues.name,
                        })
                    );

                    const setupId = this.store.selectSnapshot(UavState.lastCreatedSetupId);
                    this.navigateToUavDetails(uav.id, setupId);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private updateSetup(uav: Uav, setupValues: EditableUavSetup | EditableCustomUavSetup, isPrimarySetupOfCustomUav: boolean) {
        const setupId = this.getSelectedSetupId();

        this.store
            .dispatch(new UavActions.UpdateSetup(setupId, uav.id, setupValues, isPrimarySetupOfCustomUav))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(UavState.uavError);

                    if (error) {
                        this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavSetupEditor.setupUpdatedErrorMessage"));

                        return;
                    }

                    this.toastService.success(
                        this.transloco.translate("dtmWebAppLibUav.uavSetupEditor.setupUpdatedSuccessMessage", {
                            uavName: uav.name,
                            setupName: setupValues.name,
                        })
                    );
                    this.navigateToUavDetails(uav.id, setupId);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private getSelectedSetupId(): string {
        return this.route.snapshot.paramMap.get(SETUP_ID_ROUTE_PARAM_NAME) ?? "";
    }

    private getSelectedSetup(uav: Uav): UavSetup | undefined {
        const setupId = this.getSelectedSetupId();

        return uav.setups.find((setup) => setup.id === setupId);
    }

    private initPrimarySetup(): Observable<UavSetup> {
        return this.uav$.pipe(
            RxjsUtils.filterFalsy(),
            map((uav) => uav.setups.find((setup) => setup.isPrimary)),
            RxjsUtils.filterFalsy()
        );
    }

    private initFactoryCommunicationTypes(): Observable<CommunicationType[]> {
        return this.primarySetup$.pipe(
            RxjsUtils.filterFalsy(),
            map((setup) =>
                setup.communications.reduce<CommunicationType[]>(
                    (types, communication) => (communication.isEmbedded ? [...types, communication.type] : types),
                    []
                )
            )
        );
    }

    private getBaseSetup(): Observable<UavSetup> {
        return this.isEditMode$.pipe(
            switchMap((isEditMode) => {
                if (isEditMode) {
                    return this.selectedSetup$.pipe(RxjsUtils.filterFalsy());
                }

                return this.primarySetup$;
            })
        );
    }

    private initSetupDefaults(): Observable<EditableUavSetup> {
        return this.getBaseSetup().pipe(
            map((setup) => this.getDefaultsForSetup(setup)),
            tap((setup) => this.setSetupValues(setup))
        );
    }

    private initCustomUavSetupDefaults(): Observable<EditableCustomUavSetup> {
        return this.getBaseSetup().pipe(
            map((setup) => this.getDefaultsForCustomUavSetup(setup)),
            tap((setup) => this.setSetupValues(setup))
        );
    }

    private getDefaultsForSetup(setup: UavSetup): EditableUavSetup {
        const isEditMode = this.localStore.selectSnapshotByKey("isEditMode");

        const communications = setup.communications
            .filter((communication) => !communication.isEmbedded)
            .map((communication) => ({
                type: communication.type,
                transmissionLinkRange: communication.transmissionLinkRange,
                linkType: communication.linkType,
                linkDelay: communication.linkDelay,
                frequencies: communication.frequencies,
                isEmbedded: communication.isEmbedded,
            }));

        return {
            id: isEditMode ? setup.id : undefined,
            name: isEditMode ? setup.name : "",
            technicalSpecification: {
                maxFlightTime: setup.technicalSpecification.maxFlightTime,
                hasFlightSpeedLimit: setup.technicalSpecification.hasFlightSpeedLimit,
                maxFlightSpeed: setup.technicalSpecification.maxFlightSpeed,
                minFlightSpeed: setup.technicalSpecification.minFlightSpeed,
                failSafe: setup.technicalSpecification.failSafe,
                hasGeofencing: setup.technicalSpecification.hasGeofencing,
                hasGeocage: setup.technicalSpecification.hasGeocage,
            },
            communications,
            documents: setup.documents,
            trackings: setup.trackings,
            equipment: setup.equipment,
        };
    }

    private getDefaultsForCustomUavSetup(setup: UavSetup): EditableCustomUavSetup {
        const isEditMode = this.localStore.selectSnapshotByKey("isEditMode");

        return {
            id: isEditMode ? setup.id : undefined,
            name: isEditMode ? setup.name : "",
            technicalSpecification: setup.technicalSpecification,
            communications: setup.communications,
            documents: setup.documents,
            trackings: setup.trackings,
            equipment: setup.equipment,
        };
    }

    private async navigateToUavDetails(uavId: string, setupId: string | undefined) {
        await lastValueFrom(this.store.dispatch(new UavActions.GetUav(uavId)));

        let routeExtras: NavigationExtras | undefined;
        if (setupId) {
            routeExtras = {
                queryParams: { [SELECTED_SETUP_QUERY_PARAM_NAME]: setupId },
            };
        }

        this.router.navigate(["/uav", uavId], routeExtras);
    }
}
