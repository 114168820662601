import { Injectable } from "@angular/core";
import { NavigationAccuracy, Tracker, Uav } from "@dtm-frontend/shared/uav";
import { Page } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, Observable, switchMap } from "rxjs";
import { catchError, finalize, first, tap } from "rxjs/operators";
import { OperatorContextState } from "../../shared";
import { UavAPIService } from "../services/uav-api.service";
import {
    CapabilitiesToShare,
    EditableCustomUavSetup,
    EditableUavSetup,
    Manufacturer,
    UavCapabilities,
    UavError,
    UavListItem,
    UavListWithPages,
    UavModelProperties,
} from "../services/uav.models";
import { UavActions } from "./uav.actions";

export interface UavStateModel {
    uavList: UavListItem[] | undefined;
    uavListPages: Page | undefined;
    uavListError: UavError | undefined;
    capabilitiesToShare: CapabilitiesToShare | undefined;
    shareUavSetupsError: UavError | undefined;
    isDirty: boolean;
    currentUav: Uav | undefined;
    manufacturers: Manufacturer[] | undefined;
    trackers: Tracker[] | undefined;
    navigationAccuracyItems: NavigationAccuracy[] | undefined;
    uavError: UavError | undefined;
    capabilitiesError: UavError | undefined;
    isProcessing: boolean;
    newUavModel: UavModelProperties | undefined;
    lastCreatedUavId: string | undefined;
    lastCreatedSetupId: string | undefined;
    canShareUavs: boolean;
    attachUavDocumentsError: UavError | undefined;
}

const defaultState: UavStateModel = {
    uavList: undefined,
    uavListPages: undefined,
    uavListError: undefined,
    capabilitiesToShare: undefined,
    shareUavSetupsError: undefined,
    isDirty: false,
    currentUav: undefined,
    manufacturers: undefined,
    trackers: undefined,
    navigationAccuracyItems: undefined,
    uavError: undefined,
    capabilitiesError: undefined,
    isProcessing: false,
    newUavModel: undefined,
    lastCreatedUavId: undefined,
    lastCreatedSetupId: undefined,
    canShareUavs: false,
    attachUavDocumentsError: undefined,
};

@State<UavStateModel>({
    name: "uav",
    defaults: defaultState,
})
@Injectable()
export class UavState {
    @Selector()
    public static uavList(state: UavStateModel): UavListItem[] | undefined {
        return state.uavList;
    }

    @Selector()
    public static uavListPages(state: UavStateModel): Page | undefined {
        return state.uavListPages;
    }

    @Selector()
    public static uavListError(state: UavStateModel): UavError | undefined {
        return state.uavListError;
    }

    @Selector()
    public static currentUav(state: UavStateModel): Uav | undefined {
        return state.currentUav;
    }

    @Selector()
    public static isDirty(state: UavStateModel): boolean {
        return state.isDirty;
    }

    @Selector()
    public static manufacturers(state: UavStateModel): Manufacturer[] | undefined {
        return state.manufacturers;
    }

    @Selector()
    public static trackers(state: UavStateModel): Tracker[] | undefined {
        return state.trackers;
    }

    @Selector()
    public static navigationAccuracyItems(state: UavStateModel): NavigationAccuracy[] {
        return state.navigationAccuracyItems ?? [];
    }

    @Selector()
    public static uavError(state: UavStateModel): UavError | undefined {
        return state.uavError;
    }

    @Selector()
    public static getCapabilitiesToShare(state: UavStateModel): CapabilitiesToShare | undefined {
        return state.capabilitiesToShare;
    }

    @Selector()
    public static shareUavSetupsError(state: UavStateModel): UavError | undefined {
        return state.shareUavSetupsError;
    }

    @Selector()
    public static capabilitiesError(state: UavStateModel): UavError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static isProcessing(state: UavStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static newUavModel(state: UavStateModel): UavModelProperties | undefined {
        return state.newUavModel;
    }

    @Selector()
    public static lastCreatedUavId(state: UavStateModel): string | undefined {
        return state.lastCreatedUavId;
    }

    @Selector()
    public static lastCreatedSetupId(state: UavStateModel): string | undefined {
        return state.lastCreatedSetupId;
    }

    @Selector()
    public static canShareUavs(state: UavStateModel): boolean {
        return state.canShareUavs;
    }

    @Selector()
    public static attachUavDocumentsError(state: UavStateModel): UavError | undefined {
        return state.attachUavDocumentsError;
    }

    constructor(private readonly uavApi: UavAPIService, private readonly store: Store) {
        if (uavApi === undefined) {
            throw new Error("Initialize UavModule with .forRoot()");
        }
    }

    @Action(UavActions.GetUavList)
    public getUavList(context: StateContext<UavStateModel>, action: UavActions.GetUavList) {
        context.patchState({
            uavList: undefined,
            uavListPages: undefined,
            isProcessing: true,
        });

        return this.uavApi.getUavList(action.params).pipe(
            tap((result: UavListWithPages) =>
                context.patchState({
                    uavList: result.content,
                    uavListPages: result.pages,
                    uavListError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ uavListError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.GetUav)
    public getUav(context: StateContext<UavStateModel>, action: UavActions.GetUav) {
        context.patchState({
            uavError: undefined,
            isProcessing: true,
        });

        return this.uavApi.getUav(action.uavId).pipe(
            tap((uav: Uav) => {
                context.patchState({
                    currentUav: uav,
                    uavError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.GetCapabilities)
    public getCapabilities(context: StateContext<UavStateModel>) {
        return this.store.select(OperatorContextState.selectedContextId).pipe(
            first(FunctionUtils.isTruthy),
            switchMap((operatorId: string) => this.manageCapabilities(this.uavApi.getCapabilities(operatorId), context))
        );
    }

    @Action(UavActions.ForceGetCapabilities)
    public forceGetCapabilities(context: StateContext<UavStateModel>) {
        return this.manageCapabilities(
            this.uavApi.getCapabilities(this.store.selectSnapshot(OperatorContextState.selectedContextId) ?? ""),
            context
        );
    }

    @Action(UavActions.GetManufacturersAndModels)
    public getManufacturersAndModels(context: StateContext<UavStateModel>) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApi.getManufacturersAndModels().pipe(
            tap((manufacturers: Manufacturer[]) => {
                context.patchState({ manufacturers });
            }),
            catchError((error) => {
                context.patchState({ capabilitiesError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.GetUavModel)
    public getUavModel(context: StateContext<UavStateModel>, action: UavActions.GetUavModel) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApi.getUavModel(action.uavModelId, action.defaultCameraName).pipe(
            tap((uavModel: UavModelProperties) => {
                context.patchState({
                    newUavModel: uavModel,
                });
            }),
            catchError((error) => {
                context.patchState({ uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.MarkAsDirty)
    public markAsDirty(context: StateContext<UavStateModel>, action: UavActions.MarkAsDirty) {
        context.patchState({
            isDirty: action.isDirty,
        });
    }

    @Action(UavActions.CreateUav)
    public createUav(context: StateContext<UavStateModel>, action: UavActions.CreateUav) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApi.createUav(action.newUav).pipe(
            tap((createdUavId) => {
                context.patchState({
                    lastCreatedUavId: createdUavId,
                    uavError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ lastCreatedUavId: undefined, uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.CreateCustomUav)
    public createCustomUav(context: StateContext<UavStateModel>, action: UavActions.CreateCustomUav) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApi.createCustomUav(action.newUav).pipe(
            tap((createdUavId) => {
                context.patchState({
                    lastCreatedUavId: createdUavId,
                    uavError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ lastCreatedUavId: undefined, uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.DeleteUav)
    public deleteUav(context: StateContext<UavStateModel>, action: UavActions.DeleteUav) {
        context.patchState({
            isProcessing: true,
            uavError: undefined,
        });

        return this.uavApi.deleteUav(action.uavId).pipe(
            catchError((error) => {
                context.patchState({ uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.UpdateUav)
    public updateUav(context: StateContext<UavStateModel>, action: UavActions.UpdateUav) {
        context.patchState({
            isProcessing: true,
            uavError: undefined,
        });

        return this.uavApi.updateUav(action.uavId, action.uavValues).pipe(
            tap(() => {
                context.patchState({
                    uavError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.UpdateCustomUav)
    public updateCustomUav(context: StateContext<UavStateModel>, action: UavActions.UpdateCustomUav) {
        context.patchState({
            isProcessing: true,
            uavError: undefined,
        });

        return this.uavApi.updateCustomUav(action.uavId, action.uavValues).pipe(
            tap(() => {
                context.patchState({
                    uavError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.CreateSetup)
    public createSetup(context: StateContext<UavStateModel>, action: UavActions.CreateSetup) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApi.createSetup(action.uavId, action.setupValues).pipe(
            tap((setupId) => {
                context.patchState({
                    uavError: undefined,
                    lastCreatedSetupId: setupId,
                });
            }),
            catchError((error) => {
                context.patchState({ uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.UpdateSetup)
    public updateSetup(context: StateContext<UavStateModel>, action: UavActions.UpdateSetup) {
        context.patchState({
            isProcessing: true,
        });

        const updateSetup$ = action.isPrimarySetupOfCustomUav
            ? this.uavApi.updateCustomUavSetup(action.setupId, action.uavId, action.setupValues as EditableCustomUavSetup)
            : this.uavApi.updateSetup(action.setupId, action.uavId, action.setupValues as EditableUavSetup);

        return updateSetup$.pipe(
            tap(() => {
                context.patchState({
                    uavError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ uavError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.DeleteSetup)
    public deleteSetup(context: StateContext<UavStateModel>, action: UavActions.DeleteSetup) {
        context.patchState({
            isProcessing: true,
            uavError: undefined,
        });

        return this.uavApi.deleteSetup(action.setupId, action.uavId).pipe(
            catchError((error) => {
                context.patchState({ uavError: error, isProcessing: false });

                return EMPTY;
            }),
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(UavActions.GetShareCapabilities)
    public getShareCapabilities(context: StateContext<UavStateModel>, action: UavActions.GetShareCapabilities) {
        return this.uavApi.getShareCapabilities(action.uavModelId).pipe(
            tap((capabilitiesToShare: CapabilitiesToShare) => {
                context.patchState({ capabilitiesToShare });
            })
        );
    }

    @Action(UavActions.ShareUavSetups)
    public shareUavSetups(context: StateContext<UavStateModel>, action: UavActions.ShareUavSetups) {
        context.patchState({ shareUavSetupsError: undefined });

        return this.uavApi.shareUavSetups(action.uavId, action.shareUavModel).pipe(
            catchError((error) => {
                context.patchState({ shareUavSetupsError: error });

                return EMPTY;
            })
        );
    }

    @Action(UavActions.AttachUavDocuments)
    public attachUavDocuments(context: StateContext<UavStateModel>, action: UavActions.AttachUavDocuments) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApi.attachUavDocuments(action.uavId, action.documents).pipe(
            tap(() => {
                context.patchState({
                    attachUavDocumentsError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ attachUavDocumentsError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    private manageCapabilities(capabilities$: Observable<UavCapabilities>, context: StateContext<UavStateModel>) {
        context.patchState({
            capabilitiesError: undefined,
            isProcessing: true,
        });

        return capabilities$.pipe(
            tap((capabilities) => {
                context.patchState({
                    trackers: capabilities.trackers,
                    navigationAccuracyItems: capabilities.navigationAccuracyItems,
                    canShareUavs: capabilities.canShareUavs,
                });
            }),
            catchError((error) => {
                context.patchState({ capabilitiesError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }
}
