<div
    class="map-container"
    *ngrxLet="{
        routeEntities: routeEntities$,
        drawableFeatures: drawableFeatures$,
        flightData: flightData$,
        zoomTo: zoomTo$,
        trajectories: trajectories$,
        hemsEventData: hemsEventData$,
        missionTimeRange: missionTimeRange$,
        trackerWarning: trackerWarning$,
        violationClass: violationClass$,
        emergency: emergency$,
        isMeasureToolActive: isMeasureToolActive$,
    } as vm"
>
    <div class="loader-container" [class.show]="isProcessing$ | ngrxPush">
        <mat-spinner diameter="60" color="accent" class="loader"></mat-spinner>
    </div>
    <ac-map dtmMapPointerManager dtmMapCesiumCleanupOnDestroy>
        <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
        <dtm-map-route-viewer
            #routeViewerComponent
            [routeData]="vm.routeEntities"
            [drawableFeatures]="vm.drawableFeatures"
            [flightPositionUpdate]="vm.flightData"
            [flightPinTemplate]="flightPinTemplate"
            [zoomTo]="vm.zoomTo"
            [nearbyMissionsDrawableFeatures]="vm.drawableFeatures"
            [trajectories]="vm.trajectories"
            [hemsEventData]="vm.hemsEventData"
            [timeRange]="vm.missionTimeRange"
            [dataIdentifierKey]="'missionId'"
            (visibleAreaChanged)="handleVisibleAreaChange($event)"
        ></dtm-map-route-viewer>
        <dtm-map-hems-layer [hemsEventData]="hemsEventData$ | ngrxPush"></dtm-map-hems-layer>
        <dtm-map-lib-checkin-area-layer [checkins]="checkinList$ | ngrxPush" [isProcessing]="false"></dtm-map-lib-checkin-area-layer>
        <div class="layers-controls">
            <dtm-map-layers-with-controls
                [missionTimeRange]="missionTimeRange$ | ngrxPush"
                [shouldShowAreaBuffers]="vm.routeEntities?.data?.flightType === MissionType.BVLOS"
                [viewControls]="VIEW_CONTROLS"
                [routes]="vm.routeEntities | invoke : getRoutesFromRouteData"
                (viewControlsValueChange)="isPathVisibleControl.setValue(!!$event.Path)"
                [timeSettingOptions]="{
                    available: [ZoneTimesSetting.MissionTime, ZoneTimesSetting.Current],
                    default: ZoneTimesSetting.Current
                }"
            ></dtm-map-layers-with-controls>
        </div>
        <dtm-map-measures-cesium [isMeasureEnabled]="vm.isMeasureToolActive"></dtm-map-measures-cesium>
    </ac-map>

    <div class="overlay-container">
        <div class="custom-area">
            <ng-content></ng-content>
        </div>
        <dtm-map-controls
            isMeasureToolEnabled
            [isMeasureToolActive]="vm.isMeasureToolActive"
            (measureToolStatusChange)="updateMeasureToolStatus($event)"
        ></dtm-map-controls>
    </div>

    <ng-template #flightPinTemplate let-data>
        <dtm-map-flight-pin
            [aircraftEntity]="data"
            [isMainAircraft]="data.trackerIdentifier | invoke : isMainAircraft : vm.routeEntities?.data?.uav?.trackersIdentifiers"
            [isInVerificationMode]="!!vm.trackerWarning"
            [violationClass]="vm.violationClass"
            [name]="
                (data.trackerIdentifier | invoke : isMainAircraft : vm.routeEntities?.data?.uav?.trackersIdentifiers)
                    ? vm.routeEntities?.data?.uav?.name
                    : undefined
            "
            [emergency]="vm.emergency"
            (flightPositionUpdatesEnrich)="$event ? flightPositionUpdatesEnrich.next(data.trackerIdentifier) : null"
            (detailsOpen)="routeViewerComponent.selectAircraftEntity($event)"
        ></dtm-map-flight-pin>
    </ng-template>
</div>
